<div class="bold mb-1">{{ label }}</div>
<ng-select
  bindLabel="searchableName"
  bindValue="id"
  class="rounded w-100"
  [items]="searchableRepos"
  [placeholder]="placeholder"
  [loading]="loading"
  [searchable]="!model"
  [(ngModel)]="model"
  (change)="onRepoChange($event)">
  <ng-template
    ng-option-tmp
    ng-label-tmp
    let-item="item"
    let-index="index"
    let-search="name">
    <div
      class="mx-3 py-2 my-0 d-flex pointer justify-content-start align-items-center">
      <i class="me-3 fa fa-2x fa-solid fa-box"></i>

      <div class="h5 mb-0">
        {{ item.searchableName }}
      </div>
    </div>
  </ng-template>
</ng-select>
