import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'abbreviate' })
export class AbbreviatePipe implements PipeTransform {
  public transform(value: any, ...args: any[]): string {
    return this.getShortHandFromName(value).toLowerCase();
  }

  private getShortHandFromName(name): string {
    if (!name) {
      return '_';
    }
    if (name.length === 1) {
      return name[0];
    }
    if (name.length <= 2) {
      return name; //name.split(0, 2);
    }
    const firstLetter = name[0].toLowerCase();
    const thirdLetter = this.getNextConsonant(
      name.toLowerCase().indexOf(firstLetter.toLowerCase()) + 1,
      name
    );
    return `${firstLetter}${thirdLetter}`;
  }

  private getNextConsonant(startAt, name): string {
    if (startAt >= name.length) {
      return name[name.length - 1];
    }
    if (!name[startAt].toLowerCase().match(/[bcdfghjklmnpqrstvwxys]/)) {
      return this.getNextConsonant(startAt + 1, name);
    }
    return name[startAt];
  }
}
