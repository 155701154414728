import {
  Component,
  HostListener,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';

import { CartesianScaleOptions, ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnChanges {
  @Input() datasets = [];
  @Input() labels = [];
  @Input() yAxisLeft = '';
  @Input() yAxisRight = '';
  @Input() title = '';
  @Input() maxLimit = { value: 0, label: '' };
  @Input() showMaxLimit = false;
  @Input() height = 60;
  @Input() maxLabels = 4;
  @Input() isFullLength = false;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (window.innerWidth < 1190 && window.innerWidth >= 728) {
      this.height = 60;
      this.maxLabels = 7;
    } else if(window.innerWidth < 728) {
      this.height = 200;
      this.maxLabels = 7;
    } else {
      if (!this.isFullLength) {
        this.height = 150;
        this.maxLabels = 4;
      }
    }
    this.redrawChart();
  }

  showChart: boolean = true;

  lineChartData: ChartConfiguration['data'];
  lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 7,
        },
      },
      y: {
        position: 'left',
        title: {
          display: true,
          text: 'Common Y-Axis Label',
        },
        ticks: {
          maxTicksLimit: 4,
        },
      },
      // y1: {
      //   position: 'right',
      //   title: {
      //     display: true,
      //     text: 'Second Y-Axis Label',
      //   },
      //   ticks: {
      //     maxTicksLimit: 4,
      //   },
      //   grid: {
      //     display: false,
      //   },
      // },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            return 'Time: ' + tooltipItem[0].label;
          },
        },
        mode: 'index',
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
  };

  constructor() {}

  redrawChart() {
    this.showChart = false;
    setTimeout(() => {
      this.showChart = true;
    });
  }

  ngOnChanges(): void {
    if (window.innerWidth < 1190 && window.innerWidth >= 728) {
      this.height = 60;
      this.maxLabels = 7;
    } else if(window.innerWidth < 728) {
      this.height = 200;
      this.maxLabels = 7;
    } else {
      if (!this.isFullLength) {
        this.height = 150;
        this.maxLabels = 4;
      }
    }
    //converted string value points to number
    const newData = this.datasets.map((dataset) => ({
      ...dataset,
      data: dataset.data.map((value) =>
        parseFloat(parseFloat(value).toFixed(4))
      ),
    }));

    const datasets = newData.map((set) => {
      return {
        data: set.data,
        label: set.label,
        backgroundColor: `${set.color}25`,
        borderColor: `${set.color}`,
        pointBorderColor: `${set.color}`,
        pointBackgroundColor: `${set.color}`,
        pointHoverBackgroundColor: `${set.color}`,
        pointRadius: 1,
        fill: 'origin',
        yAxisID: 'y',
      };
    });

    //max cpu limit line for CPU Usage
    if (this.showMaxLimit) {
      datasets.push({
        data: Array(this.labels.length).fill(
          parseFloat(this.maxLimit.value.toFixed(2))
        ),
        label: this.maxLimit.label,
        yAxisID: 'y',
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        borderColor: 'red',
        pointBackgroundColor: 'rgba(255, 0, 0, 0.5)',
        pointBorderColor: 'rgba(255, 0, 0, 0.5)',
        pointHoverBackgroundColor: 'rgba(255, 0, 0, 0.5)',
        pointRadius: 1,
        fill: 'none',
      });
    }

    (this.lineChartOptions.scales['y'] as CartesianScaleOptions).title.text =
      this.yAxisLeft;
    // (this.lineChartOptions.scales['y1'] as CartesianScaleOptions).title.text =
    //   this.yAxisRight;
    (
      this.lineChartOptions.scales['x'] as CartesianScaleOptions
    ).ticks.maxTicksLimit = this.maxLabels;

    this.lineChartData = {
      datasets,
      labels: this.labels,
    };
    this.chart?.update();
  }
}
