class PreferencesService {
  constructor() {
    this.tokenKey = 'APPSTRAX_AUTH_TOKEN';
    this.refreshingTokenKey = 'APPSTRAX_REFRESHING_TOKEN';
    this.canRefreshTokenKey = 'APPSTRAX_CAN_REFRESH_TOKEN';
  }
  getItem(key) {
    try {
      return localStorage.getItem(key) || '';
    } catch (e) {
      return '';
    }
  }
  setItem(key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      // TODO handle err
    }
  }
  clearToken() {
    try {
      localStorage.removeItem(this.tokenKey);
      localStorage.removeItem(this.canRefreshTokenKey);
    } catch (e) {
      // TODO handle err
    }
  }
  getToken() {
    const token = this.getItem(this.tokenKey);
    if (!token) return null;
    return token;
  }
  setToken(token) {
    if (!token) {
      this.clearToken();
    } else {
      this.setItem(this.tokenKey, token);
    }
  }
  isRefreshingToken() {
    const token = this.getItem(this.refreshingTokenKey);
    return token == '1';
  }
  setIsRefreshingToken(refreshing) {
    if (!refreshing) {
      localStorage.removeItem(this.refreshingTokenKey);
    } else {
      this.setItem(this.refreshingTokenKey, '1');
    }
  }
  canRefreshToken() {
    const value = this.getItem(this.canRefreshTokenKey);
    return value !== 'false';
  }
  setCanRefreshToken(value) {
    this.setItem(this.canRefreshTokenKey, value + '');
  }
}
const preferences = new PreferencesService();
export { preferences };