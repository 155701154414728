import { Component, Input, EventEmitter, Output } from '@angular/core';

import { Branch } from '../../clients/repo/repo.service';

@Component({
  selector: 'app-git-branch-select',
  templateUrl: './git-branch-select.component.html',
  styleUrls: ['./git-branch-select.component.scss'],
})
export class GitBranchSelectComponent {
  @Input() public model;
  @Output() public modelChange: EventEmitter<any> = new EventEmitter();

  @Input() public label = 'Branch';
  @Input() public placeholder = 'Search/Select a Branch';
  @Input() public branches: Branch[] = [];
  @Input() public loading: boolean = false;

  public onBranchChange(branch: Branch) {
    this.modelChange.emit(branch?.name || null);
  }
}
