<div class="rounded-lg bg-black overflow-hidden log-window">
  <div scrollPosition #scrollPosition="scrollPosition" class="logs p-3" #scroll>
    <div *ngIf="loadingMore" class="loading-spinner">
      <app-spinner color="#ffdf00" size="1.5rem" class="mx-1"> </app-spinner>
    </div>

    <div *ngFor="let log of logs; trackBy: trackLog">
      <div [id]="log.id" class="d-flex w-100 justify-content-start align-items-center small">
        <span class="date me-1 unselectable">
          {{ log.date | date : 'dd/MM/yy' }}
        </span>
        <span class="time me-1 unselectable">
          {{ log.date | date : 'HH:mm:ss:SS' }}
        </span>
        <span *ngIf="multiplePods" class="date me-1 unselectable">
          {{ log.containerId?.slice(-5) }}
        </span>

        <span [class]="{ 'language-javascript': log.log.length > 0 }">{{
          log.log
          }}</span>
      </div>
    </div>
    <div *ngIf="!loading && logs?.length === 0 && !error" class="info-log">
      There are no logs at the moment.
    </div>
    <div *ngIf="error" class="info-log">
      {{ error }}
    </div>
  </div>
  <div class="bottom-bar bg-black text-white d-flex flex-wrap justify-content-between align-items-center">
    <div class="m-2 ms-3 d-flex small">
      <div *ngIf="lastUpdated" class="me-1">Last Updated:</div>
      <div class="date me-1">
        {{ lastUpdated | date : 'dd/MM/yy' }}
      </div>
      <div class="time me-1">
        {{ lastUpdated | date : 'HH:mm:ss:SS ' }}
      </div>

      <app-spinner color="#ffdf00" size="1.5rem" *ngIf="loading" class="mx-1">
      </app-spinner>
    </div>
    <div class="btn btn-link time p-0" *ngIf="showNewLogButton && !scrollPosition.isAtBottom"
      (click)="scrollToBottom()">
      New Logs
      <i class="fas fa-caret-down"></i>
    </div>
    <div class="btn btn-link scroll-to-bottom p-1 pr-4" *ngIf="!scrollPosition.isAtBottom" (click)="scrollToBottom()">
      Scroll to bottom
    </div>
  </div>
</div>
