import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import countryList from 'country-list';

@Component({
  selector: 'app-country-search-select',
  templateUrl: './country-search-select.component.html',
  styleUrls: ['./country-search-select.component.scss'],
})
export class CountrySearchSelectComponent implements OnInit {
  @Input() public countryCode: string;
  @Output() public countryCodeChange = new EventEmitter<string>();

  @Input() public error: string = '';

  @Input()
  set filterCountryCodes(countryCodes: string[]) {
    this.pFilterCountryCodes = countryCodes;
    this.filter();
  }

  private pFilterCountryCodes = [];
  private countries = [];
  public countryOptions;

  constructor() {}

  filter() {
    const useCountries = this.countries.filter((countryData) => {
      const inFilter = this.pFilterCountryCodes.filter((code) => {
        return code?.toLowerCase() === countryData.code.toLowerCase();
      });
      if (this.pFilterCountryCodes.length !== 0 && inFilter.length === 0) {
        return false;
      }
      return true;
    });
    this.countryOptions = useCountries.map((countryData) => {
      return {
        name: countryData.name,
        value: countryData.code,
      };
    });
  }

  public compare(
    a: number | string,
    b: number | string,
    isAsc: boolean
  ): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public ngOnInit(): void {
    this.countries = countryList.getData();
    this.countries.sort((a, b) => this.compare(a.name, b.name, true));
    this.filter();
  }

  public onCountryCodeChanged(): void {
    this.countryCodeChange.emit(this.countryCode);
  }
}
