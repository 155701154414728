import { ClusterPricing } from './cluster-pricing';
import { ClusterScaleConfig } from './cluster-scale-config';
import { Region } from './region';

export class Cluster {
  id: string;
  name: string;
  slug: string;
  regionKey: string;
  clusterApiId: string;
  clusterApiEndpoint: string;
  isPrivate: boolean;
  createdAt: Date;
  updatedAt: Date;
  imageUrl: string;
  tags: string;
  customerKey: string;
  capsuleApiVersion: string;

  region: Region;

  pricing?: ClusterPricing;
  scaleConfig?: ClusterScaleConfig[];
  scaleConfigByCapsuleType?: { [capsuleType: string]: ClusterScaleConfig };

  // admin
  reachable?: boolean;
  healthStatus?: any;
}
