<div class="bg-dark">
  <div class="container-fluid v2">
    <div class="row">
      <div class="col-12 px-0">
        <div class="d-flex flex-column justify-content-center p-5">
          <a [routerLink]="['/slack']" class="mx-auto mb-5 text-white text-decoration-none font-18 bold">
            Join our community
            <img class="mx-1" src="assets/images/v2/logo-slack-primary.svg" alt="Slack">
          </a>
          <div class="d-flex justify-content-between flex-column flex-sm-row py-5 border-top border-bottom border-1 border-secondary">
            <div class="d-flex flex-column col-sm-2 p-0 pb-3">
              <img class="mb-3 img-fluid" src="assets/images/v2/logo-code-capsules-brand-dark.svg" alt="Code capsule">
              <app-auth-conditional>
                <div not_authenticated>
                  <button (click)="login()"
                          class="btn btn-link btn-dark btn-block d-flex my-2 text-left text-white text-capitalize text-decoration-none">
                    Login
                    <img class="ms-auto" src="assets/images/v2/icon-arrow-right-light.svg" alt="Icon arrow right">
                  </button>
                  <button (click)="register()"
                          class="btn btn-link btn-dark btn-block d-flex text-left text-white text-capitalize text-decoration-none">
                    Register
                    <img class="ms-auto" src="assets/images/v2/icon-arrow-right-light.svg" alt="Icon arrow right">
                  </button>
                </div>
                <div authenticated>
                  <a [routerLink]="['/teams']"
                          class="btn btn-block btn-link d-flex px-3 text-white text-left text-decoration-none">
                    Spaces
                    <img class="ms-auto" src="assets/images/v2/icon-arrow-right-light.svg" alt="Icon arrow right">
                  </a>
                </div>
              </app-auth-conditional>
            </div>
            <div class="col-sm-2 p-0">
              <p class="px-3 text-white font-18 bold">Menu</p>
              <div class="nav flex-column">
                <a class="nav-link text-white" [routerLink]="['/']">Home</a>
                <a class="nav-link text-white" [routerLink]="['/solution']">Solutions</a>
                <a class="nav-link text-white" [routerLink]="['/technologies']">Technology</a>
              </div>
            </div>
            <div class="col-sm-2 p-0">
              <p class="px-3 text-white font-18 bold">Policies</p>
              <div class="nav flex-column">
                <a class="nav-link text-white" href="https://codecapsules.io/blog/terms-and-conditions">Terms & Conditions</a>
                <a class="nav-link text-white" href="https://codecapsules.io/blog/code-capsules-license-and-distribution-agreement-for-the-codecaps-marketplace-capstore/">License & Distribution</a>
                <a class="nav-link text-white" href="https://codecapsules.io/blog/use-of-codecapsules-trademark/">Trademark</a>
                <a class="nav-link text-white" href="https://codecapsules.io/blog/privacy-policy/">Privacy Policy</a>
                <a class="nav-link text-white" href="https://codecapsules.io/blog/acceptable-use-and-external-facing-services-policy/">Acceptable Use and External-facing Services Policy</a>
              </div>
            </div>
            <div class="col-sm-2 p-0">
              <p class="px-3 text-white font-18 bold">Resources</p>
              <div class="nav flex-column">
                <a class="nav-link text-white" href="https://codecapsules.io/docs/tutorials/">Tutorial</a>
                <a class="nav-link text-white" href="https://codecapsules.io/docs/">Docs</a>
                <a class="nav-link text-white" href="https://codecapsules.io/docs/FAQ/teams-spaces-capsules/">FAQs</a>
                <a class="nav-link text-white" href="https://codecapsules.io/blog/">Blogs</a>
              </div>
            </div>
            <div class="px-3 d-flex flex-column col-sm-2 p-0">
              <p class="text-white font-18 bold">Subscribe</p>
              <p class="text-white font-14">Stay up to date on how Code Capsules can help you deploy your backend and frontends to production.</p>
              <a class="btn btn-primary mb-3 rounded text-capitalize" href="https://codecapsules.io/blog/#/portal/signup">Subscribe</a>
              <p class="text-white font-14">Unsubscribe anytime.</p>
            </div>
          </div>
          <h4 class="my-5 text-center text-secondary">Made by developers for developers<span class="text-primary">.</span></h4>
          <p class="text-center text-secondary font-14 bold">© Copyright {{getYear()}}. All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
</div>
