import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../user/user.actions';
import * as TeamsActions from './team.actions';
import * as AgencyActions from '../agency/agency.actions';
import { initialState, TeamState } from './team.state';
import { StateUtils } from '../state-utils';

const teamsReducer = createReducer(
  initialState,

  // fetch all teams
  on(TeamsActions.fetchAllTeams, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.fetchAllTeamsSuccess, (state, { teams }) => ({
    ...state,
    teams,
    loading: false,
    loadedAt: new Date(),
  })),
  on(TeamsActions.fetchAllTeamsFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch team
  on(TeamsActions.fetchTeam, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.fetchTeamSuccess, (state, { team }) => ({
    ...state,
    teams: StateUtils.combineState(state.teams, team),
    loading: false,
  })),
  on(TeamsActions.fetchTeamFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // add team
  on(TeamsActions.addTeamSubmitted, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.addTeamSuccess, (state, { team }) => ({
    ...state,
    teams: [team, ...state.teams],
    loading: false,
  })),
  on(TeamsActions.addTeamFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // remove team
  on(TeamsActions.removeTeam, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.removeTeamSuccess, (state, { team }) => {
    const filteredTeams = state.teams.filter((t) => t.id !== team.id);

    // TODO - THIS SHOULD ALSO REMOVE SPACES AND CAPSULES FROM STATE

    return {
      ...state,
      teams: [...filteredTeams],
      loading: false,
    };
  }),
  on(TeamsActions.removeTeamFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // update team
  on(TeamsActions.updateTeam, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.updateTeamSuccess, (state, { team }) => ({
    ...state,
    teams: StateUtils.combineState(state.teams, team),
    loading: false,
  })),
  on(TeamsActions.updateTeamFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // update team image
  on(TeamsActions.updateTeamImage, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.updateTeamImageSuccess, (state, { team }) => ({
    ...state,
    teams: StateUtils.combineState(state.teams, team),
    loading: false,
  })),
  on(TeamsActions.updateTeamImageFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // remove team image
  on(TeamsActions.removeTeamImage, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.removeTeamImageSuccess, (state, { team }) => ({
    ...state,
    teams: StateUtils.combineState(state.teams, team),
    loading: false,
  })),
  on(TeamsActions.removeTeamImageFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch team usage
  on(TeamsActions.fetchTeamUsage, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    TeamsActions.fetchTeamUsageSuccess,
    (state, { team, teamProductUsage }) => ({
      ...state,
      usageByTeam: { ...state.usageByTeam, [team?.id]: teamProductUsage },
      loading: false,
    })
  ),
  on(TeamsActions.fetchTeamUsageFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // remove team member
  on(TeamsActions.removeTeamMemberSuccess, (state, { member, team }) => {
    const teams = state.teams.map((x) => {
      if (x.id !== team.id) return x;
      const xClone = { ...x };
      xClone.members = x.members.filter((y) => y.id !== member.id);
      return xClone;
    });
    return {
      ...state,
      teams,
      loading: false,
    };
  }),

  // fetch all team invites
  on(TeamsActions.fetchAllTeamInvites, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.fetchAllTeamInvitesSuccess, (state, { invites }) => {
    const invitesByTeam = {};
    for (const team of state.teams) {
      invitesByTeam[team.id] = invites.filter((x) => x.teamId === team.id);
    }

    return {
      ...state,
      invitesByTeam,
      loading: false,
    };
  }),
  on(TeamsActions.fetchAllTeamInvitesFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch team invites
  on(TeamsActions.fetchTeamInvites, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.fetchTeamInvitesSuccess, (state, { invites, team }) => {
    const invitesByTeam = { ...state.invitesByTeam };
    invitesByTeam[team.id] = invites;

    return {
      ...state,
      invitesByTeam,
      loading: false,
    };
  }),
  on(TeamsActions.fetchTeamInvitesFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // send team invites
  on(TeamsActions.sendTeamInvites, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.sendTeamInvitesSuccess, (state, { invites, teamId }) => {
    const invitesByTeam = { ...state.invitesByTeam };

    invitesByTeam[teamId] = StateUtils.combineStateArr(
      invitesByTeam[teamId] || [],
      invites
    );

    return {
      ...state,
      invitesByTeam,
      loading: false,
    };
  }),
  on(TeamsActions.sendTeamInvitesFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // send team owner invite
  on(TeamsActions.updateTeamOwner, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.updateTeamOwnerSuccess, (state, { invites, teamId }) => {
    const invitesByTeam = { ...state.invitesByTeam };

    invitesByTeam[teamId] = StateUtils.combineStateArr(
      invitesByTeam[teamId],
      invites
    );

    return {
      ...state,
      invitesByTeam,
      loading: false,
    };
  }),
  on(TeamsActions.updateTeamOwnerFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // cancel team invite
  on(TeamsActions.cancelTeamInvite, (state) => ({
    ...state,
    loading: true,
  })),
  on(TeamsActions.cancelTeamInviteSuccess, (state, { inviteId, teamId }) => {
    const invitesByTeam = { ...state.invitesByTeam };
    invitesByTeam[teamId] = invitesByTeam[teamId].filter(
      (invite) => invite.id !== inviteId
    );

    return {
      ...state,
      invitesByTeam,
      loading: false,
    };
  }),
  on(TeamsActions.cancelTeamInviteFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch team payment status
  on(TeamsActions.fetchTeamPaymentStatus, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    TeamsActions.fetchTeamPaymentStatusSuccess,
    (state, { status: teamPaymentStatus }) => {
      return {
        ...state,
        paymentStatusByTeam: {
          ...state.paymentStatusByTeam,
          [teamPaymentStatus.teamId]: teamPaymentStatus,
        },
        loading: false,
      };
    }
  ),
  on(TeamsActions.fetchTeamPaymentStatusFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // team agency added
  on(AgencyActions.addTeamAgencySuccess, (state, { agency, team }) => ({
    ...state,
    teams: state.teams.map((x) => {
      if (x.id == team.id) return { ...x, agency };
      return x;
    }),
  })),
  on(AgencyActions.removeTeamAgencySuccess, (state, { agency, team }) => ({
    ...state,
    teams: state.teams.map((x) => {
      if (x.id == team.id) return { ...x, agency: null };
      return x;
    }),
  })),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    teams: [],
    invites: [],
    invitesByTeam: {},
    usageByTeam: {},
    paymentStatusByTeam: {},
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: TeamState | undefined, action: Action) {
  return teamsReducer(state, action);
}
