import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-select-three-split-option',
  templateUrl: './select-three-split-option.component.html',
  styleUrls: ['./select-three-split-option.component.scss']
})
export class SelectThreeSplitOptionComponent {
  @Input() countryCode: string;
  @Input() icon: string;
  @Input() src: string;
  @Input() title: string;
  @Input() subTitle: string;

  constructor() { }



}
