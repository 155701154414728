<img
  *ngIf="imageUrl"
  [src]="'https://storage.googleapis.com/codecaps/' + imageUrl"
  class="rounded-circle border border-white" />

<div *ngIf="alt && !imageUrl" class="rounded-circle border border-white">
  <span class="py-3" >
    {{ alt | abbreviate | uppercase }}
  </span>
</div>
