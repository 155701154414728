import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnChanges {
  @Input() public model = '';
  @Output() public modelChange: EventEmitter<any> = new EventEmitter();

  @Input() public label = 'Password';
  @Input() public name = 'password';
  @Input() public placeholder = '';
  @Input() public submitted = false;

  public type = 'password';
  public validPassword = false;
  public isEightChars = false;

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes['model']) return;
    this.updatePasswordStats();
  }

  private updatePasswordStats() {
    this.isEightChars = this.model.length >= 8;
    this.validPassword = this.isEightChars;
  }

  public onPasswordChange() {
    this.updatePasswordStats();
    if (this.validPassword) {
      this.modelChange.emit(this.model);
    } else {
      this.modelChange.emit('');
    }
  }
}
