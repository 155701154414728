import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-darkmode-switch',
  templateUrl: './darkmode-switch.component.html',
  styleUrls: ['./darkmode-switch.component.scss']
})
export class DarkModeSwitchComponent {
  @Input() public label: string;
  @Input() public onLabel: string;
  @Input() public offLabel: string;


  @Input() public isOn: boolean;
  @Output() public isOnChange = new EventEmitter<any>();

  constructor() { }

  public changeValue(): void {
    this.isOn = !this.isOn;
    this.isOnChange.emit(this.isOn);
  }
}
