import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AppFormGroup } from '../../utils/app-form-group/app-form-group';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextAreaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public model;
  @Output() public modelChange: EventEmitter<any> = new EventEmitter();

  @Input() public label;
  @Input() public name = '';
  @Input() public placeholder = '';
  @Input() public autofocus = false;
  @Input() public form: AppFormGroup;

  public focus = false;

  public get formControl(): FormControl {
    return this.form.get(this.name) as FormControl;
  }

  private sub: Subscription;

  public ngOnInit(): void {
    this.sub = this.formControl.valueChanges.subscribe((change) => {
      this.modelChange.emit(change);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['model']) {
      this.formControl.setValue(this.model);
    }
  }

  public ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public isRequired(): boolean {
    if (this.formControl.validator) {
      const validator = this.formControl.validator({} as FormControl);
      if (validator && validator['required']) {
        return true;
      }
    }
    return false;
  }

  public getError(): string {
    const errorType = Object.keys(this.formControl.errors)[0];
    switch (errorType) {
      case 'required':
        return this.label ? `${this.label} is required` : 'required';
      case 'email':
        return this.label
          ? `${this.label} is not valid`
          : 'Invalid Email address';
      case 'pattern':
        return this.label
          ? `${this.label} is not valid`
          : 'Pattern is not valid';
      case 'matchOther':
        return `Passwords do not match`;
      case 'min':
        return `Min of ${this.formControl.errors['min'].min}`;
      case 'max':
        return `Max of ${this.formControl.errors['max'].max}`;
    }

    return errorType || '';
  }
}
