import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Capsule } from '../../models';
import { Status } from '../../models/status.enum';

@Component({
  selector: 'app-status-icon-circle',
  templateUrl: './status-icon-circle.component.html',
  styleUrls: ['./status-icon-circle.component.scss'],
})
export class StatusIconCircle {
  @Input() public label: string;
  @Input() public icon: string;
  @Input() public status: Status = Status.disabled;
}
