import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  email: string;

  constructor(
    private router: Router
  ) { }

  getYear(): number {
    return new Date().getFullYear();
  }

  login(): void {
    this.router.navigate(['auth', 'login']);
  }

  register(): void {
    this.router.navigate(['auth', 'registration']);
  }
}
