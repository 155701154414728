import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '@appstrax/services/auth';

import * as AgencyActions from 'src/app/core/state/agency/agency.actions';

import { AlertService } from 'src/app/core/services/alert.service';
import { UtilService } from 'src/app/core/services/util.service';

import { Agency } from 'src/app/core/models';
import { BaseComponent } from 'src/app/core/base.component';
import { AppFormGroup } from 'src/app/core/utils/app-form-group/app-form-group';

@Component({
  selector: 'app-agency-form',
  templateUrl: './agency-form.component.html',
  styleUrls: ['./agency-form.component.scss'],
})
export class AgencyFormComponent extends BaseComponent implements OnInit {
  @Input() user: User;
  @Input() agency: Agency;

  @Output() save = new EventEmitter<void>();

  public loading: boolean = false;
  private imageFile: File = null;
  public imageUrl: string = null;

  public country: string = null;
  public programmingLanguages: string[] = [];

  public form = new AppFormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    contactPerson: new FormControl('', Validators.required),
    website: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    teamSize: new FormControl('', Validators.required),
  });

  constructor(
    private alert: AlertService,
    private utils: UtilService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    if (this.agency) {
      this.form.patchValue({
        name: this.agency.name,
        description: this.agency.description,
        contactPerson: this.agency.contactPerson,
        website: this.agency.website,
        email: this.agency.email,
        phone: this.agency.phone,
        teamSize: this.agency.teamSize,
      });
      this.country = this.agency.countryOfIncorporation;
      this.programmingLanguages = this.agency.preferredProgrammingLanguages;
      this.imageUrl = this.agency.imageUrl;
    } else {
      this.form.patchValue({
        contactPerson: this.user.data.name + ' ' + this.user.data.surname,
        email: this.user.email,
      });
    }
  }

  async onSaveAgencyClicked() {
    this.form.markAsSubmitted();
    if (
      !this.form.valid ||
      !this.programmingLanguages.length ||
      !this.country ||
      (!this.imageUrl && !this.imageFile)
    ) {
      this.alert.error('Please capture all required fields');
      return;
    }

    this.loading = true;
    try {
      const agency: Agency = {
        ...this.agency,
        ...this.form.value,
        userId: this.user.id,
        countryOfIncorporation: this.country,
        preferredProgrammingLanguages: this.programmingLanguages,
      };

      const res = await this.utils.actionsToPromise(
        AgencyActions.saveAgency({ agency }),
        AgencyActions.saveAgencySuccess,
        AgencyActions.saveAgencyFailed
      );

      if (this.imageFile) {
        const payload = { agencyId: res.agency.id, file: this.imageFile };
        await this.utils.actionsToPromise(
          AgencyActions.updateAgencyImage(payload),
          AgencyActions.updateAgencyImageSuccess,
          AgencyActions.updateAgencyImageFailed
        );
      }

      this.save.emit();
    } catch (err) {
      this.alert.handle(err);
    }
    this.loading = false;
  }

  public async onImageChanged(file: File) {
    if (file) {
      this.imageFile = file;
    } else {
      this.imageFile = null;
      this.imageUrl = '';
    }
  }

  getProgrammingLanguageError() {
    if (this.programmingLanguages.length || !this.form.submitted) return '';
    return 'Please select at least one programming language';
  }

  getImageError() {
    if (this.imageFile || this.imageUrl || !this.form.submitted) return '';
    return 'Please choose an image for your agency';
  }
}
