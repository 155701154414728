<div class="alert-danger modal-header align-items-center">
  <h4 class="modal-title">{{ options.title }}</h4>

  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body" [innerHTML]="options.message"></div>
<div class="modal-body">
  <span class="">Type <code class="mx-2">confirm</code> to continue.</span>
  <input type="text" class="form-control" [(ngModel)]="input" />
  <span *ngIf="error" class="text-danger">{{ error }}</span>
</div>

<div class="modal-footer justify-content-between">
  <div class="d-flex">
    <button class="btn btn-primary me-2" (click)="onConfirm()">Confirm</button>
    <button class="btn btn-secondary" (click)="onReject()">Cancel</button>
  </div>
</div>
