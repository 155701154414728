import { Action, createReducer, on } from '@ngrx/store';
import { BillingCustomer } from '../../models/billing-customer';
import { CustomerUsage } from '../../models/customer-usage';

import * as UserActions from './user.actions';
import { initialState, UserState } from './user.state';

const userReducer = createReducer(
  initialState,

  on(UserActions.setError, (state, { error }) => ({
    ...state,
    error,
  })),

  // login
  on(UserActions.login, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.loginPending2FA, (state) => ({
    ...state,
    loading: false,
    loadedAt: new Date(),
    currentUser: null,
    pending2FA: true,
  })),
  on(UserActions.loginSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    loadedAt: new Date(),
    currentUser: user,
    pending2FA: false,
  })),
  on(UserActions.loginFailed, (state, { error }) => ({
    ...state,
    loading: false,
    currentUser: null,
    pending2FA: false,
    error,
  })),

  // logout
  on(UserActions.logout, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    currentUser: null,
    pending2FA: false,
    error: null,
    passwordResetInitiated: false,
    passwordResetCompleted: false,
    subscribeToNewsletter: false,
    customerLoading: false,
    customerLoadedAt: null,
    customerSavedAt: null,
    customer: null,
    customerUsage: null,
    customerTeamUsageByBillingPeriod: null,
    customerClusterUsageByBillingPeriod: null,
    approved: true,
    credit: 0,
    canScaleCapsulesWithoutCredit: false,
    capsuleScaleWithoutCreditLimit: 20,
    loadedAt: null,
    loading: false,
    saving: false,
  })),
  on(UserActions.logoutFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // register
  on(UserActions.register, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.registerSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    loadedAt: new Date(),
    currentUser: user,
    pending2FA: false,
  })),
  on(UserActions.registerFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // forgot password
  on(UserActions.forgotPassword, (state) => ({
    ...state,
    loading: true,
    error: null,
    passwordResetInitiated: false,
  })),
  on(UserActions.forgotPasswordSuccess, (state) => ({
    ...state,
    loading: false,
    passwordResetInitiated: true,
  })),
  on(UserActions.forgotPasswordFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // reset password
  on(UserActions.resetPassword, (state) => ({
    ...state,
    loading: true,
    error: null,
    passwordResetCompleted: false,
  })),
  on(UserActions.resetPasswordSuccess, (state) => ({
    ...state,
    loading: false,
    passwordResetCompleted: true,
  })),
  on(UserActions.resetPasswordFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // fetch customer
  on(UserActions.fetchCustomer, (state) => ({
    ...state,
    loading: true,
    customerLoading: true,
    error: null,
  })),
  on(
    UserActions.fetchCustomerSuccess,
    (state, data: { customer: BillingCustomer; approved: boolean }) => ({
      ...state,
      loading: false,
      customerLoading: false,
      customerLoadedAt: new Date(),
      customer: data?.customer?.id ? data?.customer : null, // TODO: This feels messssy! Remove types from payload
      approved: data?.approved,
    })
  ),
  on(UserActions.fetchCustomerFailed, (state, { error }) => ({
    ...state,
    loading: false,
    customerLoading: false,
    customerLoadedAt: null,
    error,
  })),

  // create customer
  on(UserActions.createCustomer, (state) => ({
    ...state,
    loading: true,
    customerLoading: true,
    customerSavedAt: null,
    error: null,
  })),
  on(UserActions.createCustomerSuccess, (state, customer: BillingCustomer) => ({
    ...state,
    loading: false,
    customerLoading: false,
    customerSavedAt: new Date(),
    customer: customer.id ? customer : null, // TODO: This feels messssy! Remove types from payload
  })),
  on(UserActions.createCustomerFailed, (state, { error }) => ({
    ...state,
    loading: false,
    customerLoading: false,
    error,
  })),

  // update customer
  on(UserActions.updateCustomer, (state) => ({
    ...state,
    loading: true,
    customerSavedAt: null,
    error: null,
  })),
  on(UserActions.updateCustomerSuccess, (state, customer: BillingCustomer) => ({
    ...state,
    customerSavedAt: new Date(),
    customer: customer.id ? customer : null, // TODO: This feels messssy! Remove types from payload
  })),
  on(UserActions.updateCustomerFailed, (state, { error }) => ({
    ...state,
    error,
  })),

  // update user
  on(UserActions.updateUser, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.updateUserSuccess, (state, { user }) => ({
    ...state,
    currentUser: user,
    loading: false,
    error: null,
  })),
  on(UserActions.updateUserFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // sent email verification
  on(UserActions.sendVerifyUserEmail, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.sendVerifyUserEmailSuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(UserActions.sendVerifyUserEmailFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // post email verification code
  on(UserActions.verifyUser, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.verifyUserSuccess, (state, { user }) => ({
    ...state,
    currentUser: user,
    loading: false,
    error: null,
  })),
  on(UserActions.verifyUserFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // fetch customer usage
  on(UserActions.fetchCustomerUsage, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    UserActions.fetchCustomerUsageSuccess,
    (state, customerUsage: CustomerUsage) => {
      const customerTeamUsageByBillingPeriod = {};
      customerUsage.teams.forEach((teamUsage) => {
        if (!customerTeamUsageByBillingPeriod[teamUsage.billingPeriod]) {
          customerTeamUsageByBillingPeriod[teamUsage.billingPeriod] = [];
        }

        customerTeamUsageByBillingPeriod[teamUsage.billingPeriod].push(
          teamUsage
        );
      });

      const customerClusterUsageByBillingPeriod = {};
      customerUsage.clusters.forEach((clusterUsage) => {
        if (!customerClusterUsageByBillingPeriod[clusterUsage.billingPeriod]) {
          customerClusterUsageByBillingPeriod[clusterUsage.billingPeriod] = [];
        }

        customerClusterUsageByBillingPeriod[clusterUsage.billingPeriod].push(
          clusterUsage
        );
      });

      return {
        ...state,
        customerUsage,
        customerTeamUsageByBillingPeriod: {
          ...customerTeamUsageByBillingPeriod,
        },
        customerClusterUsageByBillingPeriod: {
          ...customerClusterUsageByBillingPeriod,
        },
        loading: false,
        error: null,
      };
    }
  ),
  on(UserActions.fetchCustomerUsageFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // fetch customer credit
  on(UserActions.fetchCustomerCredit, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.fetchCustomerCreditSuccess, (state, data) => ({
    ...state,
    credit: data.credit,
    canScaleCapsulesWithoutCredit: data.canScaleCapsulesWithoutCredit,
    capsuleScaleWithoutCreditLimit: data.capsuleScaleWithoutCreditLimit,
    loading: false,
    error: null,
  })),
  on(UserActions.fetchCustomerCreditFailed, (state, { error }) => ({
    ...state,
    loading: false,
    // error,
  })),

  // request customer credit
  on(UserActions.requestCustomerCredit, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.requestCustomerCreditSuccess, (state, data) => ({
    ...state,
    credit: data.credit,
    canScaleCapsulesWithoutCredit: data.canScaleCapsulesWithoutCredit,
    capsuleScaleWithoutCreditLimit: data.capsuleScaleWithoutCreditLimit,
    loading: false,
    error: null,
  })),
  on(UserActions.requestCustomerCreditFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(UserActions.redeemCouponCodeFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(UserActions.redeemCouponCodeSuccess, (state, { credit }) => {
    return {
      ...state,
      credit: state.credit + credit,
      loading: false,
    };
  })
);
export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
