export interface EnvVar {
  key: string;
  value: string;
  setBy: string;

  error?: boolean;
}

export class ConfigDto {
  configs: EnvVar[];
}

const newEnvVar = (): EnvVar => {
  return {
    key: '',
    value: '',
    setBy: '',
    error: false,
  };
};

export { newEnvVar };
