// angular
import { Component, Input } from '@angular/core';

type Color = 'white' | 'gray' | 'blue' | 'red' | 'green';

@Component({
  selector: 'app-abbreviation-circle',
  templateUrl: './abbreviation-circle.component.html',
  styleUrls: ['./abbreviation-circle.component.scss'],
})
export class AbbreviationCircleComponent {
  @Input() public name = '';
  @Input() public class = '';
  @Input() public size: number = 56;
  @Input() public fontSize: number = 16;
  @Input() public color: Color = 'white';
}
