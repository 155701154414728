<div class="form-group">
  <ng-select
    placeholder="Search/Select a Country"
    [items]="countryOptions"
    [searchable]="!countryCode"
    [(ngModel)]="countryCode"
    (ngModelChange)="onCountryCodeChanged()"
    [class]="error ? 'is-invalid' : ''"
    bindLabel="name"
    bindValue="value">
    <ng-template
      ng-option-tmp
      ng-label-tmp
      let-item="item"
      let-index="index"
      let-search="name">
      <div class="d-flex align-items-center p-2">
        <div class="icon fi fis" [class]="'fi-' + item.value | lowercase"></div>
        <div class="ms-3">{{ item.name }}</div>
      </div>
    </ng-template>
  </ng-select>

  <div *ngIf="error" class="error">{{ error }}</div>
</div>
