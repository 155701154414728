import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rounded-image',
  templateUrl: './rounded-image.component.html',
  styleUrls: ['./rounded-image.component.scss'],
})
export class RoundedImageComponent {
  @Input() imageUrl;
  @Input() alt;
}
