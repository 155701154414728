var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { ConfigManager } from '../../shared/config-manager';
import { AuthResult, AuthStatus } from '../models/auth_result';
import { utils } from '../../shared/utils';
import { http } from '../../shared/http';
import { preferences } from '../preferences';
const getAuthUrl = e => ConfigManager.getAuthUrl(e);
const getUserUrl = e => ConfigManager.getUserUrl(e);
export class AuthService {
  constructor() {
    this.userListeners = new Map();
    this.tokenValidationQueue = [];
  }
  init() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateToken();
      const token = preferences.getToken();
      if (token) {
        this.onAuthStateChanged(token);
      }
    });
  }
  validateToken() {
    return __awaiter(this, void 0, void 0, function* () {
      const id = utils.generateRandomString(20);
      this.tokenValidationQueue.push(id);
      while (this.tokenValidationQueue[0] !== id) {
        yield utils.sleep(50);
      }
      yield this.dotTokenValidation();
      this.tokenValidationQueue.shift();
    });
  }
  dotTokenValidation() {
    return __awaiter(this, void 0, void 0, function* () {
      let loading = preferences.isRefreshingToken();
      let count = 0;
      while (loading && count < 20) {
        yield utils.sleep(50);
        loading = preferences.isRefreshingToken();
        count += 1;
      }
      if (count == 20) preferences.setIsRefreshingToken(false);
      const token = preferences.getToken();
      if (!token) return;
      if (utils.isTokenExpired(token)) {
        preferences.setIsRefreshingToken(true);
        yield this.tryRefreshToken();
        preferences.setIsRefreshingToken(false);
      }
    });
  }
  tryRefreshToken() {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        if (!preferences.canRefreshToken()) throw new Error();
        const result = yield this.fetchRefreshedToken();
        this.onAuthStateChanged(result.token);
      } catch (err) {
        this.onAuthStateChanged(null);
      }
    });
  }
  fetchRefreshedToken() {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        return this.post(getAuthUrl('refresh-token'), {});
      } catch (err) {
        yield this.logout();
        throw new Error('Error refreshing');
      }
    });
  }
  onAuthStateChanged(token) {
    const currentToken = preferences.getToken();
    if (currentToken == token) return;
    preferences.setToken(token);
    if (currentToken && token) {
      const currentUser = utils.getUserFromToken(currentToken);
      const user = utils.getUserFromToken(token);
      if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) == (user === null || user === void 0 ? void 0 : user.id)) return;
    }
    let user = null;
    if (token) user = utils.getUserFromToken(token);
    const listeners = this.userListeners.values();
    for (const listener of listeners) {
      listener(user);
    }
  }
  register(registerDto) {
    return __awaiter(this, void 0, void 0, function* () {
      if (yield this.isAuthenticated()) {
        yield this.logout();
      }
      const response = yield this.post(getAuthUrl('register'), registerDto);
      this.onAuthStateChanged(response.token);
      const user = utils.getUserFromToken(response.token);
      return AuthResult.authenticated(user);
    });
  }
  login(loginDto) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
      const authenticated = yield this.isAuthenticated();
      if (authenticated) yield this.logout();
      const response = yield this.post(getAuthUrl('login'), loginDto);
      preferences.setCanRefreshToken((_a = loginDto.remember) !== null && _a !== void 0 ? _a : true);
      this.onAuthStateChanged(response.token);
      const user = utils.getUserFromToken(response.token);
      if (user) {
        return AuthResult.authenticated(user);
      } else {
        return AuthResult.requireTwoFactorAuthCode();
      }
    });
  }
  verifyTwoFactorAuthCode(code) {
    return __awaiter(this, void 0, void 0, function* () {
      const token = preferences.getToken();
      if (!token) throw new Error('Invalid token');
      if (utils.isTokenExpired(token)) {
        yield this.logout();
        throw new Error('Your token has expired');
      }
      const user = utils.getUserFromToken(token);
      if (user) throw new Error('User already authenticated');
      const response = yield this.post(getAuthUrl('verify-2fa'), {
        code
      }, {
        ignoreExpiredToken: false
      });
      this.onAuthStateChanged(response.token);
      return utils.getUserFromToken(response.token);
    });
  }
  forgotPassword(forgotDto) {
    return this.post(getAuthUrl('forgot-password'), forgotDto);
  }
  resetPassword(resetDto) {
    return this.post(getAuthUrl('reset-password'), resetDto);
  }
  changePassword(dto) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateUserIsAuthenticated();
      return yield this.post(getUserUrl('change-password'), dto, {
        ignoreExpiredToken: false
      });
    });
  }
  saveUserData(data) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateUserIsAuthenticated();
      const response = yield this.post(getUserUrl('data'), data, {
        ignoreExpiredToken: false
      });
      this.onAuthStateChanged(response.token);
      return utils.getUserFromToken(response.token);
    });
  }
  sendEmailVerificationCode() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateUserIsAuthenticated();
      yield this.post(getUserUrl('send-verification-email'), {}, {
        ignoreExpiredToken: false
      });
    });
  }
  verifyEmailAddress(code) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateUserIsAuthenticated();
      const response = yield this.post(getUserUrl('verify-email'), {
        code
      }, {
        ignoreExpiredToken: false
      });
      this.onAuthStateChanged(response.token);
      return utils.getUserFromToken(response.token);
    });
  }
  generateTwoFactorAuthSecret() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateUserIsAuthenticated();
      const response = yield this.post(getUserUrl('generate-2fa-secret'), {}, {
        ignoreExpiredToken: false
      });
      this.onAuthStateChanged(response.token);
      return {
        secret: response.secret,
        qrCode: response.qr
      };
    });
  }
  enableTwoFactorAuth(code) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateUserIsAuthenticated();
      const response = yield this.post(getUserUrl('enable-2fa'), {
        code
      }, {
        ignoreExpiredToken: false
      });
      this.onAuthStateChanged(response.token);
      return utils.getUserFromToken(response.token);
    });
  }
  disableTwoFactorAuthentication() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateUserIsAuthenticated();
      const response = yield this.post(getUserUrl('disable-2fa'), {}, {
        ignoreExpiredToken: false
      });
      this.onAuthStateChanged(response.token);
      return utils.getUserFromToken(response.token);
    });
  }
  validateUserIsAuthenticated() {
    return __awaiter(this, void 0, void 0, function* () {
      const authenticated = yield this.isAuthenticated();
      if (!authenticated) throw new Error('User not authenticated');
    });
  }
  isAuthenticated() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateToken();
      return !!preferences.getToken();
    });
  }
  getAuthToken() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateToken();
      return preferences.getToken();
    });
  }
  getUser() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateToken();
      return utils.getUserFromToken(preferences.getToken());
    });
  }
  getAuthStatus() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.validateToken();
      const token = preferences.getToken();
      const user = utils.getUserFromToken(token);
      if (user) return AuthStatus.authenticated;
      if (token && !user) return AuthStatus.pendingTwoFactorAuthCode;
      return AuthStatus.notAuthenticated;
    });
  }
  logout() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.post(getAuthUrl('logout'), {});
      this.onAuthStateChanged(null);
    });
  }
  subscribeToUserChanges(listener) {
    const id = utils.generateRandomString(20);
    this.userListeners.set(id, listener);
    const token = preferences.getToken();
    const user = utils.getUserFromToken(token);
    listener(user);
    return () => this.userListeners.delete(id);
  }
  post(url, body = {}, options) {
    options = options ? options : {
      ignoreExpiredToken: true
    };
    options.withCredentials = true;
    return http.post(url, body, options);
  }
}
export class PromiseQueue {
  constructor() {
    this.busy = false;
    this.promiseFunctions = [];
  }
  push(promiseFunction) {
    this.promiseFunctions.push(promiseFunction);
    if (this.busy) return;
    this.handlePromiseQueue();
  }
  handlePromiseQueue() {
    return __awaiter(this, void 0, void 0, function* () {
      this.busy = true;
      let activePromiseFunction = this.promiseFunctions.shift();
      while (activePromiseFunction) {
        try {
          yield activePromiseFunction();
        } catch (err) {
          // handle failure
        }
        activePromiseFunction = this.promiseFunctions.shift();
      }
      this.busy = false;
    });
  }
}