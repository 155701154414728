<ng-select
  class="rounded"
  placeholder="Search/Select a team"
  [items]="teams"
  bindLabel="name"
  [searchable]="!team"
  [(ngModel)]="team"
  (ngModelChange)="teamSelectChange()">
  <ng-template ng-option-tmp ng-label-tmp let-item="item" let-search="name">
    <div
      class="px-3 py-2 my-0 d-flex pointer justify-content-start align-items-center">
      <div class="me-3">
        <div
          class="team bg-team text-dark circle-name bold d-flex align-items-center justify-content-center">
          <span class="pb-3" *ngIf="!item.imageUrl">
            {{ item.name | abbreviate | uppercase }}
          </span>
          <img
            [src]="'https://storage.googleapis.com/codecaps/' + item.imageUrl"
            *ngIf="item.imageUrl" />
        </div>
      </div>

      <div>
        <div class="h5 mb-0">
          {{ item.name }}
        </div>
        <div class="text-secondary small">
          {{ item.slug }}
        </div>
      </div>
    </div>
  </ng-template>
</ng-select>
