<div class="bold mb-1">{{ label }}</div>
<ng-select [bindLabel]="labelProperty" [bindValue]="keyProperty" class="rounded w-100" [items]="options"
  [placeholder]="placeholder" [loading]="false" [searchable]="!model" [(ngModel)]="model" (change)="select($event)">
  <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="index" let-search="name">
    <span #optionContent>
      <ng-content select="[option]"></ng-content>
    </span>
    <div *ngIf="!optionContent.children.length"
      class="mx-3 py-2 my-0 d-flex pointer justify-content-start align-items-center">
      <div *ngIf="iconProperty || icon">
        <div *ngIf="!iconProperty || !item[iconProperty]" [class]="icon" class="icon me-3"></div>
        <div *ngIf="iconProperty && item[iconProperty]" [class]="item[iconProperty]" class="icon me-3"></div>
      </div>

      <div class="h5 mb-0">
        {{ item[labelProperty] }}
      </div>
    </div>
  </ng-template>
</ng-select>
