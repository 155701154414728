class Utils {
  isTokenExpired(token, offsetSeconds = 30) {
    const expirationDate = this.getJwtExpirationDate(token);
    if (!expirationDate) return false; // not expired if no expiry date
    const nowMillis = new Date().valueOf() + offsetSeconds * 1000;
    const expiryMillis = expirationDate.valueOf();
    return nowMillis > expiryMillis;
  }
  getUserFromToken(token) {
    if (!token) return null;
    const decoded = this.decodeToken(token);
    if (!(decoded === null || decoded === void 0 ? void 0 : decoded.id)) return null;
    return decoded;
  }
  decodeToken(token) {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid JWT Token, expecting 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }
    return this.deepFreeze(JSON.parse(decoded));
  }
  deepFreeze(obj) {
    Object.keys(obj).forEach(prop => {
      if (typeof obj[prop] === 'object' && obj[prop] !== null) {
        this.deepFreeze(obj[prop]);
      }
    });
    return Object.freeze(obj);
  }
  urlBase64Decode(input) {
    let output = input.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw new Error('Illegal base64url string!');
    }
    return window.decodeURIComponent(escape(window.atob(output)));
  }
  getJwtExpirationDate(token) {
    const decoded = this.decodeToken(token);
    if (typeof decoded.exp === 'undefined') {
      return null;
    }
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(decoded.exp);
    return expirationDate;
  }
  pathJoin(...parts) {
    const separator = '/';
    const replace = new RegExp(separator + '{1,}', 'g');
    return parts.join(separator).replace(replace, separator).replace('http:/', 'http://').replace('https:/', 'https://');
  }
  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
  generateRandomString(length) {
    const options = 'abcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      const index = Math.floor(Math.random() * options.length);
      result += options[index];
    }
    return result;
  }
}
const utils = new Utils();
export { utils };