import { Component, Input, EventEmitter, Output } from '@angular/core';

import { Repo } from '../../models';

interface SearchableRepo extends Repo {
  searchableName: string;
}

@Component({
  selector: 'app-git-repo-select',
  templateUrl: './git-repo-select.component.html',
  styleUrls: ['./git-repo-select.component.scss'],
})
export class GitRepoSelectComponent {
  @Input() public model;
  @Output() public modelChange: EventEmitter<any> = new EventEmitter();

  @Input() public label = 'Git Repository';
  @Input() public placeholder = 'Search/Select a Repository';
  @Input() public loading: boolean = false;

  public searchableRepos: SearchableRepo[] = [];

  @Input()
  public set repos(repos: Repo[]) {
    this.searchableRepos = repos.map((repo) => ({
      ...repo,
      searchableName: `${repo.gitInstallation.accountName}/${repo.name}`,
    }));
  }

  public onRepoChange(repo: Repo) {
    this.modelChange.emit(repo?.id || null);
  }
}
