<!-- Repo install component -->
<div
  *ngIf="githubInstallUrl"
  class="rounded bg-dark pointer"
  (click)="navToGithubInstallUrl()"
>
  <div class="d-flex justify-content-between align-items-center p-2">
    <img src="/assets/images/v2/logo-code-capsule-primary.svg" alt="CC Logo" />
    <div class="px-2 text-white bold">
      Install or Configure Code Capsules on Github
    </div>
    <img src="/assets/images/v2/icon-github-white.svg" alt="Github Logo" />
  </div>
</div>
