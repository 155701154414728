import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Team } from '../../models';

@Component({
  selector: 'app-select-team',
  templateUrl: './select-team.component.html',
  styleUrls: ['./select-team.component.scss']
})
export class SelectTeamComponent {
  @Input() teams: Team[] = [];
  @Input() team: Team;
  @Output() teamChange = new EventEmitter<Team>();

  constructor() { }

  async teamSelectChange() {
    this.teamChange.emit(this.team);
  }
}
