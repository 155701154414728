import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-hr-left',
  templateUrl: './title-hr-left.component.html',
  styleUrls: ['./title-hr-left.component.scss']
})
export class TitleHrLeftComponent {
  @Input() marginTop = 4;
  @Input() marginBottom = 4;
  @Input() color = 'yellow';

  constructor() { }
}
