<div class="form-group">
  <label class="bold" *ngIf="label">
    {{ label }}
  </label>

  <div class="input-holder">
    <input
      required
      class="form-control dark-border"
      [type]="type"
      [name]="name"
      [(ngModel)]="model"
      [class.is-invalid]="submitted && !validPassword"
      [placeholder]="placeholder"
      (ngModelChange)="onPasswordChange()"
      data-testid="user-password" />
  </div>

  <div class="errors">
    <div *ngIf="!isEightChars">
      <i class="text-danger fa fa-x me-2"></i>
      must be at least 8 characters
    </div>
  </div>
</div>
