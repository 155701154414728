<div class="bold mb-1">{{ label }}</div>
<ng-container *ngIf="disabled">
  <span *ngIf="model && model.length > 0; else default">
    <ng-container *ngFor="let item of options">
      <span
        class="badge text-dark my-1 mx-1"
        *ngIf="model.includes(item[keyProperty])">
        {{ item[labelProperty] }}
      </span>
    </ng-container>
  </span>
  <ng-template #default>
    <span class="text-small small">None selected</span>
  </ng-template>
</ng-container>
<ng-select
  *ngIf="!disabled"
  [bindLabel]="labelProperty"
  [bindValue]="keyProperty"
  class="rounded w-100"
  [items]="options"
  [closeOnSelect]="false"
  [multiple]="true"
  [placeholder]="placeholder"
  [loading]="false"
  [searchable]="!model"
  [(ngModel)]="model"
  (change)="select($event)">
  <ng-template
    ng-multi-label-tmp
    ng-label-tmp
    let-items="items"
    let-clear="clear">
    <div
      *ngFor="let item of items | slice : 0 : 2"
      class="ng-value ng-value-background d-flex pointer justify-content-start align-items-center">
      <div *ngIf="iconProperty || icon">
        <div
          *ngIf="!iconProperty || !item[iconProperty]"
          [class]="icon"
          class="icon me-3"></div>
        <div
          *ngIf="iconProperty && item[iconProperty]"
          [class]="item[iconProperty]"
          class="icon me-3"></div>
      </div>
      <span class="ng-value-label">
        {{ item[labelProperty] }}
      </span>
      <span
        class="ng-value-icon right border-left-bg"
        (click)="clear(item)"
        aria-hidden="true"
        >×</span
      >
      <div class="h5 mb-0"></div>
    </div>
    <div class="ng-value ng-value-background" *ngIf="items.length > 2">
      <span class="ng-value-label">{{ items.length - 2 }} more...</span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <div
      [class.pb-2]="!descriptionProperty"
      class="mx-3 pt-2 my-0 d-flex pointer justify-content-start align-items-center">
      <div *ngIf="iconProperty || icon">
        <div
          *ngIf="!iconProperty || !item[iconProperty]"
          [class]="icon"
          class="icon me-3"></div>
        <div
          *ngIf="iconProperty && item[iconProperty]"
          [class]="item[iconProperty]"
          class="icon me-3"></div>
      </div>

      <div class="h5 mb-0">
        {{ item[labelProperty] }}
      </div>
    </div>
    <div
      class="ms-3 me-2 mt-2 mb-2 small text-muted"
      *ngIf="descriptionProperty">
      {{ item[descriptionProperty] }}
    </div>
  </ng-template>
</ng-select>
