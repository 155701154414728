import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import {
  Plan,
  CapsuleType,
  ClusterPricing,
  ClusterScaleConfig,
} from 'src/app/core/models';

import { CapsulePricingService } from '../../services/capsule-pricing.service';

@Component({
  selector: 'app-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss'],
})
export class ProductSummaryComponent implements OnChanges {
  @Input()
  capsuleType: CapsuleType;

  @Input()
  plan: Plan;

  @Input()
  clusterPricing: ClusterPricing;

  @Input()
  clusterScaleConfig?: ClusterScaleConfig;

  @Input()
  buttonText: string;

  @Input()
  productName = 'Test Product';

  @Input()
  faButtonIcon = 'fa fa-solid fa-arrow-right';

  cpuCost: number = 0;
  ramCost: number = 0;
  gpuCost: number = 0;
  storageCost: number = 0;
  totalCost: number = 0;

  @Output() buttonPress = new EventEmitter();

  constructor(private pricing: CapsulePricingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.cpuCost = 0;
    this.ramCost = 0;
    this.gpuCost = 0;
    this.storageCost = 0;
    this.totalCost = 0;

    if (this.clusterPricing && this.plan) {
      this.calculateTotal();
    }
  }

  private calculateTotal(): void {
    const pricing = this.clusterPricing;
    const plan = this.plan;
    this.cpuCost = this.pricing.calculateCpuCost(pricing, plan);
    this.ramCost = this.pricing.calculateRamCost(pricing, plan);
    this.gpuCost = this.pricing.calculateGpuCost(pricing, plan);
    this.storageCost = this.pricing.calculateStorageCost(pricing, plan);
    this.totalCost = this.pricing.calculateCapsulePrice(pricing, plan);
  }
}
