import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { scrollPositionDirective } from '../../directives/scroll-position.directive';
import { CapsuleLogs, Log } from '../../models/capsule-log';

interface LogView {
  id: string;
  log: string;
  date: Date;
  capsuleId: string;
  containerId: string;
  namespace: string;
  streamType: string;
}

@Component({
  selector: 'app-log-viewer',
  templateUrl: './log-viewer.component.html',
  styleUrls: ['./log-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogViewerComponent implements OnChanges, AfterViewInit {
  @ViewChild('scroll', { read: scrollPositionDirective })
  public scroll: scrollPositionDirective;

  showNewLogButton = false;
  logs: LogView[] = [];

  lastUpdated: Date = null;
  loading = true;
  loadingMore = false;
  error: string = '';
  buildId: string = null;

  @Input() multiplePods = false;
  @Input() capsuleLogs: CapsuleLogs;

  @Output() loadMore: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['capsuleLogs']) {
      this.loadingMore = false;
      this.loading = this.capsuleLogs?.loading ?? true;
      this.error = this.capsuleLogs?.error ?? null;
      this.lastUpdated = this.capsuleLogs?.fetchedAt ?? null;

      if (!this.capsuleLogs || !this.capsuleLogs.logs) return;

      if (
        this.logs.length != this.capsuleLogs.logs.length ||
        this.buildId != this.capsuleLogs.buildId
      ) {
        this.buildId = this.capsuleLogs.buildId;
        this.parseLogs([...this.capsuleLogs.logs]);
      }
    }
  }

  private parseLogs(logs: Log[]) {
    let padZeros = 0;
    if (logs.length) padZeros = (logs[0].time + '').length - 13;

    const currentTopId = this.logs.length ? this.logs[0].id : null;

    this.logs = logs.reverse().map((log) => {
      return {
        ...log,
        date: this.getLogDate(log, padZeros),
      };
    });

    this.updateScrollPosition(currentTopId);
  }

  private getLogDate(log: Log, padZeros: number): Date {
    return new Date(log.time / 10 ** padZeros);
  }

  private updateScrollPosition(prevTopId: string) {
    if (this.scroll?.isAtTop && prevTopId) {
      setTimeout(() => document.getElementById(prevTopId).scrollIntoView());
    }

    if (!this.scroll?.isAtBottom) {
      this.showNewLogButton = true;
    } else {
      setTimeout(() => this.scrollToBottom());
    }
  }

  scrollToBottom() {
    this.scroll.scrollToBottom();
    this.showNewLogButton = false;
  }

  ngAfterViewInit(): void {
    this.scroll.doOnScrollToBottom(async () => {
      this.showNewLogButton = false;
    });
    this.scroll.doOnScrollNearTop(async () => {
      this.loadingMore = true;
      this.loadMore.emit();
    });
    this.scrollToBottom();
  }

  public trackLog(index: number, log: LogView) {
    return log.id;
  }
}
