import { environment } from '../../../../environments/environment';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-repo-button',
  templateUrl: './add-repo-button.component.html',
  styleUrls: ['./add-repo-button.component.scss'],
})
export class AddRepoButtonComponent implements OnInit {
  @Input() user;
  githubInstallUrl = '';

  constructor() {}

  ngOnInit(): void {
    this.githubInstallUrl = `${environment.githubInstallationBaseUrl}${this.user.id}`;
  }

  navToGithubInstallUrl(): void {
    if (!this.githubInstallUrl) {
      return;
    }

    window.open(this.githubInstallUrl, '_blank');
  }
}
