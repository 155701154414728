import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-conditional',
  templateUrl: './auth-conditional.component.html',
  styleUrls: ['./auth-conditional.component.scss']
})
export class AuthConditionalComponent {

  // TODO:
  constructor(
    // private authService: NgAuthService
  ) { }

  public isAuthenticated(): boolean {
    // return this.authService.isAuthenticated();
    return false;
  }
}
