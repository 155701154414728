<div class="card bg-dark text-light" [class.top]="buttonText">
  <div *ngIf="capsuleType" class="py-3 px-4 font-courier-monospaced">
    <div class="d-flex w-100 justify-content-between my-2">
      <div>{{ capsuleType?.name }}</div>
    </div>

    <div class="d-flex justify-content-center align-items-center h-75"
      *ngIf="plan && !clusterPricing">
      <app-spinner color="#ffdf00" size="1.5rem" class="mx-1"> </app-spinner>
    </div>

    <div *ngIf="plan && clusterPricing && clusterScaleConfig">

      <div *ngIf="clusterScaleConfig.cpu && plan.cpu"
        class="d-flex w-100 justify-content-between my-2">
        <div>{{ plan.cpu * 100 | number: '1.0-2' }}% CPU</div>
        <div>{{ cpuCost | currency }}</div>
      </div>

      <div *ngIf="clusterScaleConfig.ram &&  plan.ram"
        class="d-flex w-100 justify-content-between my-2">
        <div>{{ plan.ram | number: '1.0-2' }}GB RAM</div>
        <div>{{ ramCost | currency }}</div>
      </div>

      <div *ngIf="clusterScaleConfig.gpu && plan.gpu"
        class="d-flex w-100 justify-content-between my-2">
        <div>{{ plan.gpu | number: '1.0' }} GPU</div>
        <div>{{ gpuCost | currency }}</div>
      </div>

      <div *ngIf="clusterScaleConfig.storage && plan.storage"
        class="d-flex w-100 justify-content-between my-2">
        <div>{{ plan.storage | number: '1.0' }} GB Storage</div>
        <div>{{ storageCost | currency }}</div>
      </div>

      <div *ngIf="clusterScaleConfig.replicas && plan.replicas"
        class="d-flex w-100 justify-content-between my-2">
        <div>
          {{ plan.replicas | number: '1.0' }}
          {{ plan.replicas === 1 ? 'Replica' : 'Replicas' }}
        </div>
        <div>Total * Replicas</div>
      </div>

      <div class="d-flex w-100 justify-content-between mt-4 mb-2">
        <div>Total</div>
        <div>
          {{ totalCost | currency }}
        </div>
      </div>

    </div>
  </div>
</div>

<div *ngIf="buttonText" (click)="buttonPress.emit(null)"
  class="bottom btn-primary mt-0 d-flex justify-content-between pointer">
  <div class="btn">
    {{ buttonText }}
  </div>
  <div class="btn">
    <i [class]="faButtonIcon"></i>
  </div>
</div>
