import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'confirm-modal.component.html'
})
export class ConfirmModalComponent {

  public options: ConfirmModalOptions;
  public hide: boolean = false;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  onConfirm(): void {
    this.options.confirm();
    this.close();
  }

  onReject(): void  {
    if (this.options.reject) {
      this.options.reject();
    }
    this.close();
  }

  close(): void  {
    this.bsModalRef.hide();
  }
}

export class ConfirmModalOptions {
  title: string;
  message: string;
  confirmText?: string;
  confirm: () => void;
  reject?: () => void;
}
