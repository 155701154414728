<app-edit-picture
  [imageUrl]="imageUrl"
  (change)="onImageChanged($event)"
  [error]="getImageError()">
</app-edit-picture>

<div class="pt-4">
  <span class="bold font-18">Name</span>
  <app-input
    type="text"
    [form]="form"
    name="name"
    placeholder="Enter agency name">
  </app-input>
</div>

<div class="pt-4">
  <span class="bold font-18">Description</span>
  <app-input
    type="text"
    [form]="form"
    name="description"
    placeholder="What type of projects does your agency work on?">
  </app-input>
</div>

<div class="pt-4">
  <span class="bold font-18">Website</span>
  <app-input
    type="text"
    [form]="form"
    name="website"
    placeholder="Enter agency website">
  </app-input>
</div>

<div class="pt-4">
  <span class="bold font-18">Team Size</span>
  <app-input
    type="number"
    [form]="form"
    name="teamSize"
    placeholder="Enter team size">
  </app-input>
</div>

<div class="pt-4">
  <span class="bold font-18">Contact Person</span>
  <app-input
    type="text"
    [form]="form"
    name="contactPerson"
    placeholder="Enter a contact name">
  </app-input>
</div>

<div class="pt-4">
  <span class="bold font-18">Email</span>
  <app-input
    type="email"
    [form]="form"
    name="email"
    placeholder="Enter a your agency email address">
  </app-input>
</div>

<div class="pt-4">
  <span class="bold font-18">Phone Number</span>
  <app-input
    type="tel"
    [form]="form"
    name="phone"
    placeholder="Enter your agency phone number">
  </app-input>
</div>

<div class="pt-4">
  <label class="font-18 bold">Country Of Incorporation</label>

  <app-country-search-select
    [error]="!country && form.submitted ? 'Please select a country' : ''"
    [(countryCode)]="country">
  </app-country-search-select>
</div>

<div class="pt-4">
  <span class="bold font-18">Preferred Programming Languages</span>

  <app-language-multi-select
    [error]="getProgrammingLanguageError()"
    [(selectedLanguages)]="programmingLanguages">
  </app-language-multi-select>
</div>

<app-spinner-button
  class="mt-5"
  (click)="onSaveAgencyClicked()"
  [disabled]="false"
  [loading]="loading">
  Save Agency
</app-spinner-button>
