import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Capsule } from '../../models';

@Component({
  selector: 'app-capsule-status-circle',
  templateUrl: './capsule-status-circle.component.html',
  styleUrls: ['./capsule-status-circle.component.scss'],
})
export class CapsuleStatusCircleComponent implements OnChanges {
  @Input() public capsule: Capsule;

  replicas: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.capsule) return;
    const replicas = [];
    for (let i = 0; i < this.capsule.replicas; i++) {
      replicas.push(i.toString());
    }
    this.replicas = replicas;
  }
}
