<h3 *ngIf="title.length">{{ title }}</h3>
<div class="flex">
  <div class="flex-item chart" *ngIf="showChart" [style.height]="height">
    <canvas baseChart
        type="line"
        [height]="height"
        [data]="lineChartData"
        [options]="lineChartOptions">
    </canvas>
  </div>
</div>
