import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent {
  @Input() public label: string;
  @Input() public onLabel: string;
  @Input() public offLabel: string;

  @Input() public isOn: boolean;
  @Output() public isOnChange = new EventEmitter<boolean>();

  constructor() {}

  public onValueChange(event: Event): void {
    event.preventDefault();
    // this.isOn = ;
    this.isOnChange.emit(!this.isOn);
  }
}
