import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
    selector: 'app-country-mobile-select',
    templateUrl: './country-mobile-select.component.html',
    styleUrls: ['./country-mobile-select.component.scss'],
})
export class CountryMobileSelectComponent {
    @Input() countryCode;
    @Output() countryCodeChange: EventEmitter<any> = new EventEmitter();

    // separateDialCode = false;
    // SearchCountryField = SearchCountryField;
    // CountryISO = CountryISO;
    // PhoneNumberFormat = PhoneNumberFormat;
    // preferredCountries: CountryISO[] = [CountryISO.SouthAfrica];
    phoneForm = new FormGroup({
        phone: new FormControl('', [Validators.required])
    });

    onCountrySelect(): void {
        this.countryCodeChange.emit(this.countryCode);
    }
}
