import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'info-modal.component.html',
})
export class InfoModalComponent {
  public options: InfoModalOptions;
  public hide: boolean = false;

  constructor(private bsModalRef: BsModalRef) {}

  onAcknowledgeClicked(): void {
    this.options.onAcknowledge();
    this.bsModalRef.hide();
  }

  close(): void {
    if (this.options.onClose) this.options.onClose();
    this.bsModalRef.hide();
  }
}

export interface InfoModalOptions {
  title: string;
  message: string;
  acknowledgeText?: string;
  onAcknowledge: () => void;
  onClose?: () => void;
}
