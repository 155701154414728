import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { AlertService } from '../../services/alert.service';

interface Image {
  file: File;
  display: SafeResourceUrl;
}

@Component({
  selector: 'app-edit-picture',
  templateUrl: './edit-picture.component.html',
  styleUrls: ['./edit-picture.component.scss'],
})
export class EditPictureComponent implements OnInit {
  @Input() public text: string;
  @Input() public error: string;
  @Input() public imageUrl?: string;
  @Input() public file?: File;

  @Output() change = new EventEmitter<File>();

  private maxKb: number = 1024;
  public image: Image = {
    file: null,
    display: null,
  };

  constructor(
    private alert: AlertService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.file) this.readFile(this.file);
  }

  onFileSelected(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];

      if (file.size / 1000 > this.maxKb) {
        const err = 'This image is too large, max size is ' + this.maxKb + 'KB';
        this.alert.error(err);
      } else {
        this.readFile(file);
      }

      event.target.value = '';
    }
  }

  private readFile(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const display = this.sanitizer.bypassSecurityTrustResourceUrl(
        e.target.result
      );
      this.image.file = file;
      this.image.display = display;
      this.change.emit(this.image.file);
    };
    reader.readAsDataURL(file);
  }

  public removeFile() {
    this.clear();
    this.change.emit(this.image.file);
  }

  public clear() {
    this.image = {
      file: null,
      display: null,
    };
  }
}
