var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { ConfigManager } from '../../shared/config-manager';
import { http } from '../../shared/http';
export class UserService {
  find(query) {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParams = {};
      if (query === null || query === void 0 ? void 0 : query.where) {
        queryParams.where = JSON.stringify(query.where);
      }
      if (query === null || query === void 0 ? void 0 : query.order) {
        queryParams.order = JSON.stringify(query.order);
      }
      if ((query === null || query === void 0 ? void 0 : query.offset) || (query === null || query === void 0 ? void 0 : query.limit)) {
        queryParams.offset = query.offset;
        queryParams.limit = query.limit;
      }
      const params = new URLSearchParams(queryParams);
      return http.get(ConfigManager.getUserUrl('?' + params));
    });
  }
}