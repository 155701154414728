// angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';

// pages
import { NotFoundPage } from './pages/not-found/not-found.page';
import { SlackRedirectPage } from './pages/slack-redirect/slack-redirect.page';

// components
import { AbbreviationCircleComponent } from './components/abbreviation-circle/abbreviation-circle.component';
import { AddRepoButtonComponent } from './components/add-repo-button/add-repo-button.component';
import { AlertComponent } from './components/alert/alert.component';
import { AuthConditionalComponent } from './components/auth-conditional/auth-conditional.component';
import { CapsulePlanComponent } from './components/capsule-plan/capsule-plan.component';
import { CheckboxFormlessComponent } from './components/checkbox-formless/checkbox-formless.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { CountryMobileSelectComponent } from './components/country-mobile-select/country-mobile-select.component';
import { CountrySearchSelectComponent } from './components/country-search-select/country-search-select.component';
import { CubicCurveComponent } from './components/cubic-curve/cubic-curve.component';
import { DarkModeSwitchComponent } from './components/darkmode-switch/darkmode-switch.component';
import { EditPictureComponent } from './components/edit-picture/edit-picture.component';
import { FooterComponent } from './components/footer/footer.component';
import { InputComponent } from './components/input/input.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { KeyValueComponent } from './components/key-value/key-value.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { LogViewerComponent } from './components/log-viewer/log-viewer.component';
import { EnvVarEditorComponent } from './components/evn-var-editor/evn-var-editor.component';
import { NotificationBannerComponent } from './components/notification-banner/notification-banner.component';
import { ProductSummaryComponent } from './components/product-summary/product-summary.component';
import { ProfileCircleComponent } from './components/profile-circle/profile-circle.component';
import { SelectCloudComponent } from './components/select-cloud/select-cloud.component';
import { SelectRegionComponent } from './components/select-region/select-region.component';
import { SelectTeamComponent } from './components/select-team/select-team.component';
import { SelectSpaceComponent } from './components/select-space/select-space.component';
import { SelectThreeSplitOptionComponent } from './components/select-three-split-option/select-three-split-option.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TextConfirmModalComponent } from './modals/text-confirm-modal/text-confirm-modal.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { GitRepoSelectComponent } from './components/git-repo-select/git-repo-select.component';
import { GitBranchSelectComponent } from './components/git-branch-select/git-branch-select.component';
import { NavigationDropdownComponent } from './components/navigation-dropdown/navigation-dropdown.component';
import { AgencyFormComponent } from './components/agency-form/agency-form.component';
import { LanguageMultiSelectComponent } from './components/language-multi-select/language-multi-select.component';
import { RoundedImageComponent } from './components/rounded-image/rounded-image.component';
import { TextAreaComponent } from './components/textarea/textarea.component';
import { CapsuleStatusCircleComponent } from './components/capsule-status-circle/capsule-status-circle.component';
import { SelectComponent } from './components/select/select.component';

// services
import { BillingService } from './services/billing.service';
import { CapsuleService } from './clients/capsule/capsule.service';
import { CapsuleUpdatesService } from './services/capsule-updates.service';
import { ClusterService } from './clients/cluster/cluster.service';
import { DemoCapsuleService } from './clients/demo-capsule/demo-capsule.service';
import { DomainService } from './clients/domain/domain.service';
import { HttpService } from './clients/http.service';
import { MarketplaceService } from './http/marketplace/marketplace.service';
import { MetadataService } from './services/metadata.service';
import { ModalService } from '../core/modals/services/modal.service';
import { ProductService } from './clients/product/product.service';
import { RepoService } from './clients/repo/repo.service';
import { SellerService } from './http/seller/seller.service';
import { SpaceService } from './clients/space/space.service';
import { TeamService } from './clients/team/team.service';
import { UserService } from './clients/user/user.service';
import { UtilService } from './services/util.service';
import { CapsulePricingService } from './services/capsule-pricing.service';
import { ReCaptchaService } from './services/recaptcha.service';

// modules
import { UiModule } from './ui/ui.module';

import { AbbreviatePipe } from './utils/pipes/abbreviate.pipe';
import { scrollPositionDirective } from './directives/scroll-position.directive';
import { BackupScheduleFrequencySelect } from './components/backup-schedule-select/backup-frequency-select.component';
import { DropdownSelect } from './components/dropdown-select/dropdown-select.component';
import { Slider } from './components/slider/slider.component';
import { StatusIconCircle } from './components/status-icon-circle/status-icon-circle.component';
import { ConfirmDangerModalComponent } from './modals/confirm-danger-modal/confirm-danger-modal.component';
import { DropdownMultiSelect } from './components/dropdown-multiple-select/dropdown-multi-select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgChartsModule,
    NgSelectModule,
    PopoverModule,
    // NgxIntlTelInputModule,
    NgxSliderModule,
    ReactiveFormsModule,
    RouterModule,
    UiModule,
    MonacoEditorModule,
    ModalModule.forRoot(),
  ],
  declarations: [
    scrollPositionDirective,
    AbbreviatePipe,

    // modals
    ConfirmModalComponent,
    ConfirmDangerModalComponent,
    TextConfirmModalComponent,

    // components
    AbbreviationCircleComponent,
    AddRepoButtonComponent,
    AlertComponent,
    AuthConditionalComponent,
    AuthConditionalComponent,
    CapsulePlanComponent,
    CheckboxFormlessComponent,
    CountryMobileSelectComponent,
    CountrySearchSelectComponent,
    CubicCurveComponent,
    DarkModeSwitchComponent,
    EditPictureComponent,
    FooterComponent,
    InputComponent,
    PasswordInputComponent,
    KeyValueComponent,
    LineChartComponent,
    ListItemComponent,
    LogViewerComponent,
    NotificationBannerComponent,
    ProductSummaryComponent,
    ProfileCircleComponent,
    SelectCloudComponent,
    SelectRegionComponent,
    SelectTeamComponent,
    SelectSpaceComponent,
    SelectThreeSplitOptionComponent,
    SwitchComponent,
    Slider,
    StatusIconCircle,
    TooltipComponent,
    EnvVarEditorComponent,
    BackupScheduleFrequencySelect,
    GitRepoSelectComponent,
    GitBranchSelectComponent,
    DropdownSelect,
    DropdownMultiSelect,
    NavigationDropdownComponent,
    AgencyFormComponent,
    LanguageMultiSelectComponent,
    RoundedImageComponent,
    TextAreaComponent,
    CapsuleStatusCircleComponent,
    // Pages
    NotFoundPage,
    SlackRedirectPage,
    SelectComponent,
  ],
  exports: [
    AbbreviatePipe,
    AbbreviationCircleComponent,
    AddRepoButtonComponent,
    AlertComponent,
    AuthConditionalComponent,
    AuthConditionalComponent,
    CapsulePlanComponent,
    CheckboxFormlessComponent,
    CountryMobileSelectComponent,
    CountrySearchSelectComponent,
    CubicCurveComponent,
    DarkModeSwitchComponent,
    EditPictureComponent,
    FooterComponent,
    InputComponent,
    PasswordInputComponent,
    KeyValueComponent,
    StatusIconCircle,
    LineChartComponent,
    ListItemComponent,
    LogViewerComponent,
    NotificationBannerComponent,
    NotFoundPage,
    ProductSummaryComponent,
    ProfileCircleComponent,
    SelectCloudComponent,
    SelectRegionComponent,
    SelectTeamComponent,
    SelectSpaceComponent,
    SelectThreeSplitOptionComponent,
    DropdownSelect,
    DropdownMultiSelect,
    SlackRedirectPage,
    SwitchComponent,
    Slider,
    TooltipComponent,
    ConfirmModalComponent,
    EnvVarEditorComponent,
    BackupScheduleFrequencySelect,
    GitRepoSelectComponent,
    GitBranchSelectComponent,
    NavigationDropdownComponent,
    AgencyFormComponent,
    RoundedImageComponent,
    TextAreaComponent,
    CapsuleStatusCircleComponent,
    SelectComponent,
  ],
  providers: [
    BillingService,
    CapsuleService,
    CapsuleUpdatesService,
    ClusterService,
    DemoCapsuleService,
    DomainService,
    HttpService,
    MarketplaceService,
    MetadataService,
    ModalService,
    ProductService,
    RepoService,
    SellerService,
    SpaceService,
    TeamService,
    UserService,
    UtilService,
    CapsulePricingService,
    ReCaptchaService,
  ],
})
export class CoreModule { }
