<div *ngIf="label" class="bold font-18 mt-4">{{ label }}</div>
<div class="app-slider">
  <ngx-slider
    [(value)]="value"
    (valueChange)="change($event)"
    [(highValue)]="highValue"
    [options]="options"
    [manualRefresh]="manualRefresh"
    [triggerFocus]="triggerFocus"
    (userChangeStart)="(userChangeStart)"
    (userChange)="(userChange)"
    (userChangeEnd)="(userChangeEnd)">
  </ngx-slider>
</div>
