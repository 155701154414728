import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-hr',
  templateUrl: './title-hr.component.html',
  styleUrls: ['./title-hr.component.scss']
})
export class TitleHrComponent {
  @Input() marginTop = 4;
  @Input() marginBottom = 4;
  @Input() color = 'yellow';

  constructor() { }
}
