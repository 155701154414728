import { Directive, HostListener, ElementRef} from '@angular/core';

@Directive({
  selector: '[scrollPosition]',
  exportAs:'scrollPosition'
})
export class scrollPositionDirective {

  isAtBottom = true;
  isAtTop = true;
  isNearBottom = false;
  isNearTop = true;
  private hasRunBottomFuncs = false;
  private hasRunTopFuncs = false;
  private hasRunNearBottomFuncs = false;
  private hasRunNearTopFuncs = false;

  private top:number;
  private offSetHeight:number;
  private scrollHeight:number;
  nativeElement: any;

  private bottomFuncs = [];
  private topfuncs = [];
  private nearBottomFuncs = [];
  private nearTopfuncs = [];

  constructor(private eleRef: ElementRef){
    this.nativeElement = eleRef.nativeElement;
  }

  @HostListener('scroll') onScrollEvent(event:Event){
    this.getParams();
    this.handleAtTop();
    this.handleNearTop();
    this.handleAtBottom();
    this.handleNearBottom();

  }
  private handleAtTop() {
    this.isAtTop = this.checkIfIsAtTop(10);
    if (this.isAtTop) {
      if (!this.hasRunTopFuncs) {
        this.hasRunTopFuncs = true;
        this.topfuncs.forEach((fn) => fn());
      }
    }
    if (!this.isAtTop) { this.hasRunTopFuncs = false; }
  }

  private handleNearTop() {
    this.isNearTop = this.checkIfIsAtTop(300);
    if (this.isNearTop) {
      if (!this.hasRunNearTopFuncs) {
        this.hasRunNearTopFuncs = true;
        this.nearTopfuncs.forEach((fn) => fn());
      }
    }
    if (!this.isNearTop) { this.hasRunNearTopFuncs = false; }
  }

  private handleAtBottom() {
    this.isAtBottom = this.checkIfIsAtBottom(10);
    if (this.isAtBottom) {
      if (!this.hasRunBottomFuncs) {
        this.hasRunBottomFuncs = true;
        this.bottomFuncs.forEach((fn) => fn());
      }
    }
    if (!this.isAtBottom) { this.hasRunBottomFuncs = false; }
  }

  private handleNearBottom() {
    this.isNearBottom = this.checkIfIsAtBottom(300);
    if (this.isNearBottom) {
      if (!this.hasRunNearBottomFuncs) {
        this.hasRunNearBottomFuncs = true;
        this.nearBottomFuncs.forEach((fn) => fn());
      }
    }
    if (!this.isNearBottom) { this.hasRunNearBottomFuncs = false; }

  }

  private checkIfIsAtTop(thresholdPx: number): boolean {
    if (thresholdPx > this.scrollHeight) {
      return false;
    }
    const isMoreThanBottomThreshold = this.top > -thresholdPx;
    const isLessThanTopThreshold = this.top < thresholdPx;
    const isBetweenThresholds = isMoreThanBottomThreshold && isLessThanTopThreshold;
    return isBetweenThresholds;

  }

  private checkIfIsAtBottom(thresholdPx: number) {
    if (thresholdPx > this.scrollHeight) {
      return false;
    }
    const scrollHeightTop = this.scrollHeight - this.offSetHeight;
    const scrollHeightBottom = this.scrollHeight + this.offSetHeight;
    const isMoreThanBottomThreshold = this.top < scrollHeightBottom + thresholdPx;
    const isLessThanTopThreshold = this.top > scrollHeightTop - thresholdPx;
    const isBetweenThresholds = isMoreThanBottomThreshold && isLessThanTopThreshold;
    return isBetweenThresholds;
  }

  scrollToBottom() {
    this.getParams();
    this.nativeElement.scrollTop = this.scrollHeight - this.offSetHeight;
  }


  scrollToTop() {
    this.getParams();
    this.nativeElement.scrollTop = 0;
  }

  getParams() {
    this.top = this.eleRef.nativeElement.scrollTop;
    this.offSetHeight = this.eleRef.nativeElement.offsetHeight;
    this.scrollHeight = this.eleRef.nativeElement.scrollHeight;
  }

  doOnScrollToTop(func) {
    this.topfuncs.push(func);
  }

  doOnScrollNearTop(func) {
    this.nearTopfuncs.push(func);
  }

  doOnScrollToBottom(func) {
    this.bottomFuncs.push(func);
  }

  doOnScrollNearBottom(func) {
    this.nearBottomFuncs.push(func);
  }
}
