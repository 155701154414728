<div class="form-group">
  <ng-select
    [items]="programmingLanguages"
    [multiple]="true"
    [closeOnSelect]="false"
    bindLabel="language"
    bindValue="language"
    placeholder="Select preferred programming languages"
    [(ngModel)]="selectedLanguages"
    (ngModelChange)="onSelectedLanguagesChange()"
    [class]="error ? 'is-invalid' : ''">
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value ng-value-background" *ngFor="let item of items">
        <span class="ng-value-label border-right-bg"> {{ item }}</span>
        <span
          class="ng-value-icon right border-left-bg"
          (click)="clear(item)"
          aria-hidden="true"
          >×</span
        >
      </div>
    </ng-template>
  </ng-select>

  <div *ngIf="error" class="error">{{ error }}</div>
</div>
