<div
  class="thumbnail"
  [ngClass]="[color, class]"
  [ngStyle]="{
    width: size + 'px',
    height: size + 'px',
    fontSize: fontSize + 'px'
  }">
  {{ name | abbreviate | uppercase }}
</div>
