import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnChanges {
  @Input() public model;
  @Output() public modelChange: EventEmitter<any> = new EventEmitter();

  @Input() public label = 'Select an option';
  @Input() public placeholder = 'Select';
  @Input() public options: any[] = [];
  @Input() public labelProperty: string = 'name';
  @Input() public keyProperty: string = 'name';
  @Input() public iconProperty: string = '';
  @Input() public icon: string = '';
  @Input() public class: string = '';

  public optionsAreObjects: boolean = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.options && this.options.length) {
      const x = this.options[0];
      this.optionsAreObjects =
        typeof x === 'object' && !Array.isArray(x) && x !== null;
    }
  }

  public select(option) {
    if (this.optionsAreObjects) {
      this.modelChange.emit(option[this.keyProperty]);
    } else {
      this.modelChange.emit(option);
    }
  }
}
