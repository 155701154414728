import { createAction, props } from '@ngrx/store';
import {
  Team,
  TeamInvitation,
  TeamPaymentStatus,
  TeamProductUsage,
} from '../../models';

// Fetch teams
export const fetchAllTeams = createAction(
  '[Team API] Fetch All Teams Initiated'
);
export const fetchAllTeamsSuccess = createAction(
  '[Team API] Fetch All Teams Success',
  props<{ teams: Team[] }>()
);
export const fetchAllTeamsFailed = createAction(
  '[Team API] Fetch All Teams Failed',
  props<{ error: Error }>()
);

// Fetch team
export const fetchTeam = createAction(
  '[Team API] Fetch Team Initiated',
  props<{ teamIdOrSlug: string }>()
);
export const fetchTeamSuccess = createAction(
  '[Team API] Fetch Team Success',
  props<{ team: Team }>()
);
export const fetchTeamFailed = createAction(
  '[Team API] Fetch Team Failed',
  props<{ error: Error }>()
);

// Add team
export const addTeamSubmitted = createAction(
  '[Team API] Add Team Initiated',
  props<{ team: Team }>()
);
export const addTeamSuccess = createAction(
  '[Team API] Add Team Success',
  props<{ team: Team }>()
);
export const addTeamFailed = createAction(
  '[Team API] Add Team Failed',
  props<{ error: Error }>()
);

// Remove team
export const removeTeam = createAction(
  '[Team] Remove Team Submitted',
  props<{ teamId: string }>()
);
export const removeTeamSuccess = createAction(
  '[Team] Remove Team Success',
  props<{ team: Team }>()
);
export const removeTeamFailed = createAction(
  '[Team] Remove Team Failed',
  props<{ error: Error }>()
);

// Fetch team usage
export const fetchTeamUsage = createAction(
  '[Team] Fetch Team Usage',
  props<{ billingPeriod?: string; team: Team }>()
);
export const fetchTeamUsageSuccess = createAction(
  '[Team] Fetch Team Usage Success',
  props<{ team: Team; teamProductUsage: TeamProductUsage[] }>()
);
export const fetchTeamUsageFailed = createAction(
  '[Team] Fetch Team Usage Failed',
  props<{ error: Error }>()
);

// Remove team member
export const removeTeamMember = createAction(
  '[Team] Remove Team Member',
  props<{ member: any; team: Team }>()
);
export const removeTeamMemberSuccess = createAction(
  '[Team] Remove Team Member Success',
  props<{ member: any; team: Team }>()
);
export const removeTeamMemberFailed = createAction(
  '[Team] Remove Team Member Failed',
  props<{ error: Error }>()
);

// Update team
export const updateTeam = createAction(
  '[Team] Update Team Submitted',
  props<{ team: Team }>()
);
export const updateTeamSuccess = createAction(
  '[Team] Update Team Success',
  props<{ team: Team }>()
);
export const updateTeamFailed = createAction(
  '[Team] Update Team Failed',
  props<{ error: Error }>()
);

// Update team owner
export const updateTeamOwner = createAction(
  '[Team] Update Team Owner Submitted',
  props<{ teamId: string; emailAddress: string }>()
);
export const updateTeamOwnerSuccess = createAction(
  '[Team] Update Team Owner Success',
  props<{ invites: TeamInvitation[]; teamId: string }>()
);
export const updateTeamOwnerFailed = createAction(
  '[Team] Update Team Owner Failed',
  props<{ error: Error }>()
);

// Update team image
export const updateTeamImage = createAction(
  '[Team] Update Team Image Submitted',
  props<{ teamId: string; file: File }>()
);
export const updateTeamImageSuccess = createAction(
  '[Team] Update Team Image Success',
  props<{ team: Team }>()
);
export const updateTeamImageFailed = createAction(
  '[Team] Update Team Image Failed',
  props<{ error: Error }>()
);

// Remove team image
export const removeTeamImage = createAction(
  '[Team] Remove Team Image Submitted',
  props<{ teamId: string }>()
);
export const removeTeamImageSuccess = createAction(
  '[Team] Remove Team Image Success',
  props<{ team: Team }>()
);
export const removeTeamImageFailed = createAction(
  '[Team] Remove Team Image Failed',
  props<{ error: Error }>()
);

// Fetch all team invites
export const fetchAllTeamInvites = createAction(
  '[Team API] Fetch All Team Invites'
);
export const fetchAllTeamInvitesSuccess = createAction(
  '[Team API] Fetch All Team Invites Success',
  props<{ invites: TeamInvitation[] }>()
);
export const fetchAllTeamInvitesFailed = createAction(
  '[Team API] Fetch All Team Invites Failed',
  props<{ error: Error }>()
);

// Fetch team invites
export const fetchTeamInvites = createAction(
  '[Team API] Fetch Team Invites',
  props<{ team: Team }>()
);
export const fetchTeamInvitesSuccess = createAction(
  '[Team API] Fetch Team Invites Success',
  props<{ invites: TeamInvitation[]; team: Team }>()
);
export const fetchTeamInvitesFailed = createAction(
  '[Team API] Fetch Team Invites Failed',
  props<{ error: Error }>()
);

// Send team invites
export const sendTeamInvites = createAction(
  '[Team API] Send Team Invites',
  props<{ emailAddresses: string[]; teamId: string }>()
);
export const sendTeamInvitesSuccess = createAction(
  '[Team API] Send Team Invites Success',
  props<{ invites: TeamInvitation[]; teamId: string }>()
);
export const sendTeamInvitesFailed = createAction(
  '[Team API] Send Team Invites Failed',
  props<{ error: Error }>()
);

// Cancel team invite
export const cancelTeamInvite = createAction(
  '[Team API] Cancel Team Invite Initiated',
  props<{ inviteId: string; teamId: string }>()
);
export const cancelTeamInviteSuccess = createAction(
  '[Team API] Cancel Team Invite Success',
  props<{ inviteId: string; teamId: string }>()
);
export const cancelTeamInviteFailed = createAction(
  '[Team API] Cancel Team Invite Failed',
  props<{ error: Error }>()
);

// Fetch team payment status
export const fetchTeamPaymentStatus = createAction(
  '[Team] Fetch Team Payment Status',
  props<{ team: Team }>()
);
export const fetchTeamPaymentStatusSuccess = createAction(
  '[Team] Fetch Team Payment Status Success',
  props<{ status: TeamPaymentStatus }>()
);
export const fetchTeamPaymentStatusFailed = createAction(
  '[Team] Fetch Team Payment Status Failed',
  props<{ error: Error }>()
);
