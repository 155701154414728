import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
})
export class DropdownSelect {
  @Input() public model;
  @Output() public modelChange: EventEmitter<any> = new EventEmitter();
  @Input() public label = 'Select an option';
  @Input() public placeholder = 'Select';
  @Input() public options: any[] = [];
  @Input() public loading: boolean = false;
  @Input() public labelProperty: string = 'name';
  @Input() public keyProperty: string = 'name';
  @Input() public iconProperty: string = '';
  @Input() public icon: string = '';

  public select(option) {
    this.modelChange.emit(option[this.keyProperty]);
  }
}
