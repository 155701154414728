var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import axios, { AxiosError, AxiosHeaders } from 'axios';
import { appstraxAuth } from '../../auth';
import { preferences } from '../../auth/preferences';
import { ConfigManager } from '../config-manager';
export class HttpService {
  get(url, options) {
    return __awaiter(this, void 0, void 0, function* () {
      const headers = yield this.getHeaders({
        url,
        httpOptions: options
      });
      const withCredentials = (options === null || options === void 0 ? void 0 : options.withCredentials) || false;
      return this.handleAxiosRequest(axios.get(url, {
        headers,
        withCredentials
      }));
    });
  }
  post(url, body = {}, options) {
    return __awaiter(this, void 0, void 0, function* () {
      const headers = yield this.getHeaders({
        url,
        httpOptions: options
      });
      const withCredentials = (options === null || options === void 0 ? void 0 : options.withCredentials) || false;
      return this.handleAxiosRequest(axios.post(url, body, {
        headers,
        withCredentials
      }));
    });
  }
  put(url, body = {}, options) {
    return __awaiter(this, void 0, void 0, function* () {
      const headers = yield this.getHeaders({
        url,
        httpOptions: options
      });
      const withCredentials = (options === null || options === void 0 ? void 0 : options.withCredentials) || false;
      return this.handleAxiosRequest(axios.put(url, body, {
        headers,
        withCredentials
      }));
    });
  }
  patch(url, body = {}, options) {
    return __awaiter(this, void 0, void 0, function* () {
      const headers = yield this.getHeaders({
        url,
        httpOptions: options
      });
      const withCredentials = (options === null || options === void 0 ? void 0 : options.withCredentials) || false;
      return this.handleAxiosRequest(axios.patch(url, body, {
        headers,
        withCredentials
      }));
    });
  }
  delete(url, options) {
    return __awaiter(this, void 0, void 0, function* () {
      const headers = yield this.getHeaders({
        url,
        httpOptions: options
      });
      const withCredentials = (options === null || options === void 0 ? void 0 : options.withCredentials) || false;
      return this.handleAxiosRequest(axios.delete(url, {
        headers,
        withCredentials
      }));
    });
  }
  uploadFile(url, file, options) {
    return __awaiter(this, void 0, void 0, function* () {
      const body = new FormData();
      body.append('file', file);
      const headers = yield this.getHeaders({
        url,
        httpOptions: options,
        isFile: true
      });
      return this.handleAxiosRequest(axios.post(url, body, {
        headers,
        onUploadProgress: e => {
          if (options.onUploadProgress) {
            options.onUploadProgress({
              total: e.total,
              loaded: e.loaded,
              percent: e.progress
            });
          }
        }
      }));
    });
  }
  getHeaders(options) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
      let headers = {};
      if ((_a = options.httpOptions) === null || _a === void 0 ? void 0 : _a.withCredentials) {
        headers['Access-Control-Allow-Origin'] = this.getBaseUrl(options.url);
      } else {
        headers['Access-Control-Allow-Origin'] = '*';
      }
      if (!options.isFile) {
        headers['Content-Type'] = 'application/json';
      }
      headers['x-api-key'] = ConfigManager.getApiKey();
      headers = Object.assign(headers, ((_b = options.httpOptions) === null || _b === void 0 ? void 0 : _b.headers) || {});
      let token = '';
      if ((_c = options.httpOptions) === null || _c === void 0 ? void 0 : _c.ignoreExpiredToken) {
        token = preferences.getToken();
      } else {
        token = yield appstraxAuth.getAuthToken();
      }
      if (token) headers['Authorization'] = 'Bearer ' + token;
      return new AxiosHeaders(headers);
    });
  }
  getBaseUrl(url) {
    let baseUrl = '';
    let httpFormat = '';
    if (url.includes('http://')) {
      baseUrl = url.replace('http://', '');
      httpFormat = 'http://';
    }
    if (url.includes('https://')) {
      baseUrl = url.replace('https://', '');
      httpFormat = 'https://';
    }
    baseUrl = baseUrl.substring(0, baseUrl.indexOf('/'));
    return httpFormat + baseUrl;
  }
  handleAxiosRequest(request) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const response = yield request;
        return response.data;
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response) {
            const message = error.response.data.message;
            const status = error.response.status;
            throw new HttpError(message, status);
          } else {
            const status = error.status;
            const message = error.message;
            throw new HttpError(message, status);
          }
        }
        throw error;
      }
    });
  }
}
class HttpError {
  constructor(message, status) {
    this.message = message;
    this.status = status;
  }
}