import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-language-multi-select',
  templateUrl: './language-multi-select.component.html',
  styleUrls: ['./language-multi-select.component.scss'],
})
export class LanguageMultiSelectComponent {
  @Input() selectedLanguages = [];
  @Output() selectedLanguagesChange = new EventEmitter<string[]>();

  @Input() error: string = '';

  programmingLanguages: string[] = [
    'JavaScript',
    'TypeScript',
    'Python',
    'Java',
    'C#',
    'C++',
    'Ruby',
    'Swift',
    'Go',
    'PHP',
    'Kotlin',
    'Rust',
    'Scala',
    'Perl',
    'HTML',
    'CSS',
    'SQL',
    'Objective-C',
    'Assembly',
    'R',
    // 'Shell',
    'Matlab',
    'Dart',
    // 'Lua',
    'Haskell',
    'Groovy',
    'VB.NET',
    // 'PowerShell',
    // 'Delphi',
  ];

  onSelectedLanguagesChange() {
    this.selectedLanguagesChange.emit(this.selectedLanguages);
  }
}
