<button
  [disabled]="disabled || loading"
  class="btn btn-block text-capitalize"
  [ngClass]="color + ' ' + class"
  [type]="type"
  data-testid="spinner-button">
  <ng-content></ng-content>
  <div *ngIf="loading" class="button-spinner">
    <app-spinner></app-spinner>
  </div>
</button>
