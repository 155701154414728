import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-navigation-dropdown',
  templateUrl: './navigation-dropdown.component.html',
  styleUrl: './navigation-dropdown.component.scss',
})
export class NavigationDropdownComponent implements OnInit {
  @Input() tabList = [];
  selectedOption: any;
  firstUrlSegment: any;

  constructor(private router: Router) {}

  ngOnInit() {
    this.selectedOption = this.extractLastSegment(this.router.url);
    this.firstUrlSegment = this.extractFirstSegment(this.router.url);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.selectedOption = this.extractLastSegment(event.urlAfterRedirects);
        this.firstUrlSegment = this.extractFirstSegment(
          event.urlAfterRedirects
        );
      });
  }

  navigate() {
    if (this.selectedOption) {
      this.router.navigateByUrl(
        `${this.firstUrlSegment}${this.selectedOption}`
      );
    }
  }

  private extractLastSegment(url: string): string {
    const segments = url.split('/');
    return segments[segments.length - 1] || segments[segments.length - 2];
  }

  private extractFirstSegment(url: string): string {
    const segments = url.split('/');
    let combinedText = '';
    for (let i = 0; i < segments.length - 1; i++) {
      combinedText =
        combinedText + segments[i] + (i < segments.length - 1 ? '/' : '');
    }
    return combinedText;
  }
}
