import {
  trigger,
  transition,
  style,
  animate,
  AnimationTriggerMetadata,
  state,
} from '@angular/animations';

export class AnimationService {
  public static sideInOut(): AnimationTriggerMetadata {
    return trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', zIndex: 10 }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        style({ zIndex: 9 }),
        animate('200ms ease-out', style({ transform: 'translateX(100%)' })),
      ]),
    ]);
  }

  public static collapsible() {
    return trigger('collapsible', [
      state(
        '*',
        style({
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
          borderBottom: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        })
      ),
      state(
        'true',
        style({
          overflow: 'inherit',
        })
      ),
      transition(
        '* => true',
        animate('0.1s cubic-bezier(0.55, 0.085, 0.68, 0.53)')
      ),
      transition(
        '* => false',
        animate('0.1s cubic-bezier(0.55, 0.085, 0.68, 0.53)')
      ),
    ]);
  }

  public static opacityInOut(): AnimationTriggerMetadata {
    return trigger('OpacityInOut', [
      state(
        'in',
        style({
          opacity: 1,
        })
      ),
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(
          '200ms linear',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition(':leave', [
        style({
          opacity: 1,
        }),
        animate(
          '200ms linear',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]);
  }

  public static heightInOut(): AnimationTriggerMetadata {
    const enterAnimationTimeAndCurve = '200ms  linear';
    const exitAnimationTimeAndCurve = '200ms ease';
    return trigger('HeightInOut', [
      // state('in', style({
      //   'max-height': '0'
      // })),
      transition(':enter', [
        style({
          'max-height': '0vh',
        }),
        animate(
          enterAnimationTimeAndCurve,
          style({
            'max-height': '100vh',
          })
        ),
      ]),
      transition(':leave', [
        style({
          'max-height': '100vh',
        }),
        animate(
          exitAnimationTimeAndCurve,
          style({
            'max-height': '0vh',
          })
        ),
      ]),
    ]);
  }

  public static capsuleCreateCardSlideInOut(): AnimationTriggerMetadata {
    const translatePercentage = 5;
    const animationTimeAndCurve = '150ms ease';
    return trigger('CapsuleCreateCardSlideInOut', [
      transition('* => Forward', [
        style({
          transform: `translateX(${translatePercentage}%)`,
          opacity: 0,
          zIndex: 10,
        }),
        animate(
          animationTimeAndCurve,
          style({
            opacity: 1,
            transform: 'translateX(0%)',
          })
        ),
      ]),
      transition('* => Backward', [
        style({
          transform: `translateX(-${translatePercentage}%)`,
          opacity: 0,
          zIndex: 10,
        }),
        animate(
          animationTimeAndCurve,
          style({
            opacity: 1,
            transform: 'translateX(0%)',
          })
        ),
      ]),
    ]);
  }

  public static vanish(
    xMovement: number = 10,
    yMovement: number = 0,
    durationMs: number = 200
  ) {
    return [
      this.vanishIn(xMovement, yMovement, durationMs),
      this.vanishOut(xMovement, yMovement, durationMs),
      this.vanishInOut(xMovement, yMovement, durationMs),
    ];
  }

  public static vanishIn(
    xMovement: number = 10,
    yMovement: number = 0,
    durationMs: number = 200
  ): AnimationTriggerMetadata {
    return trigger('VanishIn', [
      transition(':enter', [
        style({
          transform: `translate(${xMovement}%, ${yMovement}%)`,
          opacity: 0,
          zIndex: 10,
        }),
        animate(
          `${durationMs}ms ease`,
          style({
            opacity: 1,
            transform: `translate(0%, 0%)`,
          })
        ),
      ]),
    ]);
  }
  public static vanishOut(
    xMovement: number = 10,
    yMovement: number = 0,
    durationMs: number = 200
  ): AnimationTriggerMetadata {
    return trigger('VanishOut', [
      transition(':leave', [
        style({
          transform: 'translate(0%, 0%)',
          opacity: 1,
          zIndex: 9,
        }),
        animate(
          `${durationMs}ms ease`,
          style({
            opacity: 0,
            transform: `translate(${-xMovement}%, ${-yMovement}%)`,
          })
        ),
      ]),
    ]);
  }
  public static vanishInOut(
    xMovement: number = 10,
    yMovement: number = 0,
    durationMs: number = 200
  ): AnimationTriggerMetadata {
    return trigger('VanishInOut', [
      transition(':enter', [
        style({
          transform: `translate(${xMovement}%, ${yMovement}%)`,
          opacity: 0,
          zIndex: 10,
        }),
        animate(
          `${durationMs}ms ease`,
          style({
            opacity: 1,
            transform: `translate(0%, 0%)`,
          })
        ),
      ]),
      transition(':leave', [
        style({
          transform: 'translate(0%, 0%)',
          opacity: 1,
          zIndex: 9,
        }),
        animate(
          `${durationMs}ms ease`,
          style({
            opacity: 0,
            transform: `translate(${-xMovement}%, ${-yMovement}%)`,
          })
        ),
      ]),
    ]);
  }
}
