import { createAction, props } from '@ngrx/store';
import {
  LoginDto,
  User,
  RegisterDto,
  ForgotPasswordDto,
  ResetPasswordDto,
} from '@appstrax/services/auth';

import { BillingCustomer, CustomerUsage, CreditResponse } from '../../models';

export const setError = createAction(
  '[User] Set Error',
  props<{ error: Error }>()
);

export const login = createAction('[User] Login Initiated', props<LoginDto>());
export const loginPending2FA = createAction('[User] Login Pending 2FA');
export const loginSuccess = createAction(
  '[User] Login Success',
  props<{ user: User }>()
);
export const loginFailed = createAction(
  '[User] Login Failed',
  props<{ error: Error }>()
);

export const logout = createAction('[User] Logout Initiated');
export const logoutSuccess = createAction('[User] Logout Success');
export const logoutFailed = createAction(
  '[User] Logout Failed',
  props<{ error: Error }>()
);

export const register = createAction(
  '[User] Register Initiated',
  props<RegisterDto>()
);
export const registerSuccess = createAction(
  '[User] Register Success',
  props<{ user: User }>()
);
export const registerFailed = createAction(
  '[User] Register Failed',
  props<{ error: Error }>()
);

export const subscribe = createAction(
  '[User] Subscribe to Newsletter',
  props<{ subscribed: boolean }>()
);
export const subscribeSuccess = createAction(
  '[User] Subscribe to Newsletter Success',
  props<{ subscribed: boolean }>()
);
export const subscribeFailed = createAction(
  '[User] Subscribe to Newsletter Failed',
  props<{ error: Error }>()
);

export const forgotPassword = createAction(
  '[User] Forgot Password Initiated',
  props<ForgotPasswordDto>()
);
export const forgotPasswordSuccess = createAction(
  '[User] Forgot Password Success'
);
export const forgotPasswordFailed = createAction(
  '[User] Forgot Password Failed',
  props<{ error: Error }>()
);

export const resetPassword = createAction(
  '[User] Reset Password Initiated',
  props<ResetPasswordDto>()
);
export const resetPasswordSuccess = createAction(
  '[User] Reset Password Success'
);
export const resetPasswordFailed = createAction(
  '[User] Reset Password Failed',
  props<{ error: Error }>()
);

export const fetchCustomer = createAction('[User] Fetch Customer Initiated');
export const fetchCustomerSuccess = createAction(
  '[User] Fetch Customer Success',
  props<{ customer: BillingCustomer; approved: boolean }>()
);
export const fetchCustomerFailed = createAction(
  '[User] Fetch Customer Failed',
  props<{ error: Error }>()
);

export const createCustomer = createAction(
  '[User] Create Customer Initiated',
  props<BillingCustomer>()
);
export const createCustomerSuccess = createAction(
  '[User] Create Customer Success',
  props<BillingCustomer>()
);
export const createCustomerFailed = createAction(
  '[User] Create Customer Failed',
  props<{ error: Error }>()
);

export const updateCustomer = createAction(
  '[User] Update Customer Initiated',
  props<BillingCustomer>()
);
export const updateCustomerSuccess = createAction(
  '[User] Update Customer Success',
  props<BillingCustomer>()
);
export const updateCustomerFailed = createAction(
  '[User] Update Customer Failed',
  props<{ error: Error }>()
);

// update user
export const updateUser = createAction(
  '[User] Update user',
  props<{ user: User }>()
);
export const updateUserSuccess = createAction(
  '[User] Update User Success',
  props<{ user: User }>()
);
export const updateUserFailed = createAction(
  '[User] Update User Failed',
  props<{ error: Error }>()
);

// sent verify user email
export const sendVerifyUserEmail = createAction(
  '[User] Send Verify User Email'
);
export const sendVerifyUserEmailSuccess = createAction(
  '[User] Send Verify User Email Success'
);
export const sendVerifyUserEmailFailed = createAction(
  '[User] Send Verify User Email Failed',
  props<{ error: Error }>()
);

// verify user
export const verifyUser = createAction(
  '[User] Verify User Email',
  props<{ code: string }>()
);
export const verifyUserSuccess = createAction(
  '[User] Verify User Email Success',
  props<{ user: User }>()
);
export const verifyUserFailed = createAction(
  '[User] Verify User Email Failed',
  props<{ error: Error }>()
);

// fetch customer usage
export const fetchCustomerUsage = createAction(
  '[User] Customer Usage Fetch Initiated',
  props<{ billingPeriod?: string }>()
);
export const fetchCustomerUsageSuccess = createAction(
  '[User] Customer Usage Fetch Success',
  props<CustomerUsage>()
);
export const fetchCustomerUsageFailed = createAction(
  '[User] Customer Usage Fetch Failed',
  props<{ error: Error }>()
);

// fetch customer credit
export const fetchCustomerCredit = createAction('[User] Fetch Customer Credit');
export const fetchCustomerCreditSuccess = createAction(
  '[User] Fetch Customer Credit Success',
  props<CreditResponse>()
);
export const fetchCustomerCreditFailed = createAction(
  '[User] Fetch Customer Credit Failed',
  props<{ error: Error }>()
);

// request customer credit
export const requestCustomerCredit = createAction(
  '[User] Request Customer Credit',
  props<{ amount: number }>()
);
export const requestCustomerCreditSuccess = createAction(
  '[User] Request Customer Credit Success',
  props<CreditResponse>()
);
export const requestCustomerCreditFailed = createAction(
  '[User] Request Customer Credit Failed',
  props<{ error: Error }>()
);


// redeem coupon code
export const redeemCouponCode = createAction(
  '[User] Redeem Customer Coupon',
  props<{ couponCode: string }>()
);
export const redeemCouponCodeSuccess = createAction(
  '[User] Redeem Customer Coupon Success',
  props<{ credit: number }>()
);
export const redeemCouponCodeFailed = createAction(
  '[User] Redeem Customer Coupon Failed',
  props<{ error: Error }>()
);
