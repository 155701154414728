<div class="d-flex justify-content-center align-items-center">
  <div class="team my-2 text-dark bold">
    <span class="py-3" *ngIf="text && !image?.display && !imageUrl">
      {{ text | abbreviate | uppercase }}
    </span>
    <img
      [src]="
        image?.display ||
        'https://storage.googleapis.com/codecaps/' + imageUrl
      "
      *ngIf="image?.display || imageUrl"
    />
    <a
      (click)="fileInput.click()"
      href="javascript:;"
      class="edit-btn d-flex align-items-center justify-content-center"
      tooltip="Select Image"
      placement="bottom"
    >
      <i class="fa fa-camera"></i>
    </a>
    <a
      (click)="removeFile()"
      href="javascript:;"
      class="remove-btn d-flex align-items-center justify-content-center"
      tooltip="Remove Image"
      placement="top"
      *ngIf="image?.display || imageUrl"
    >
      <i class="icon-cross"></i>
    </a>
  </div>

  <input
    style="display: none"
    type="file"
    (change)="onFileSelected($event)"
    #fileInput
  />
</div>
<div *ngIf="error" class="error">{{ error }}</div>
