import { PointerType } from '@angular-slider/ngx-slider/pointer-type';
import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class Slider {
  @Input() public value = 1;
  @Output() public valueChange: EventEmitter<number> = new EventEmitter();
  @Input() public highValue = null;
  @Output() public highValueChange: EventEmitter<number> = new EventEmitter();
  @Output() public userChangeStart: EventEmitter<number> = new EventEmitter();
  @Output() public userChange: EventEmitter<number> = new EventEmitter();
  @Output() public userChangeEnd: EventEmitter<number> = new EventEmitter();
  @Output() public manualRefresh: EventEmitter<any> = new EventEmitter();
  @Input() public triggerFocus: any;
  @Input() public label: string = null;
  @Input() public floor = 1;
  @Input() public ceil = 10;
  @Input() public step = 0.1;
  @Input() public displayFn: (value: number) => string = (value: number) =>
    `${Number(value).toFixed(0)} units`;

  options = {
    floor: this.floor,
    ceil: this.ceil,
    step: this.step,
    translate: this.displayFn,
  };

  public change(option) {
    this.valueChange.emit(option);
  }

  ngOnChanges(changes) {
    this.options = {
      floor: this.floor,
      ceil: this.ceil,
      step: this.step,
      translate: this.displayFn,
    };
  }
}
