<div class="modal-header align-items-center">
  <h4 class="modal-title">{{ options.title }}</h4>

  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"></button>
</div>

<div class="modal-body" [innerHTML]="options.message"></div>

<div class="modal-footer justify-content-end">
  <button class="btn btn-primary btn-small" (click)="onAcknowledgeClicked()">
    {{ options.acknowledgeText ?? 'OK' }}
  </button>
</div>
