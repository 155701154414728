<div class="form-group mb-0">
  <label *ngIf="label">{{ label }} </label>
  <div class="d-flex align-items-center pointer" (click)="onValueChange($event)">
    <div class="switch-bg" [class.bg-unchecked]="!isOn" [class.bg-checked]="isOn">
      <div class="switch-center" [class.checked]="isOn"></div>
    </div>
    <div class="ms-3 label-text no-wrap">
      {{ isOn ? onLabel : offLabel }}
    </div>
  </div>
</div>
