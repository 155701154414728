import { Component, Input } from '@angular/core';

export enum Color {
  primary = 'btn-primary',
  secondary = 'btn-secondary',
  success = 'btn-success',
  danger = 'btn-danger',
  warning = 'btn-warning',
  info = 'btn-info',
}

@Component({
  selector: 'app-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss'],
})
export class SpinnerButtonComponent {
  @Input() class = '';
  @Input() color: Color = Color.primary;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() type = '';
}
