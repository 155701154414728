import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Space } from '../../models';

@Component({
  selector: 'app-select-space',
  templateUrl: './select-space.component.html',
  styleUrls: ['./select-space.component.scss']
})
export class SelectSpaceComponent {
  @Input() spaces: Space[] = [];
  @Input() space: Space;
  @Output() spaceChange = new EventEmitter<Space>();

  constructor() { }

  async onSpaceChange() {
    this.spaceChange.emit(this.space);
  }
}
