import { OnInit } from '@angular/core';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { isPlatformBrowser } from '@angular/common';

import { Plan } from '../../models/plan';
import { ClusterScaleConfig } from '../../models/cluster-scale-config';

@Component({
  selector: 'app-capsule-plan',
  templateUrl: './capsule-plan.component.html',
  styleUrls: ['./capsule-plan.component.scss'],
})
export class CapsulePlanComponent implements OnInit {
  pClusterScaleConfig?: ClusterScaleConfig;

  @Input() plan: Plan;
  @Input() plans: Plan[];
  @Output() planChange = new EventEmitter<Plan>();

  @Input() loading: boolean = false;

  @Input() set clusterScaleConfig(clusterScaleConfig: ClusterScaleConfig) {
    this.pClusterScaleConfig = clusterScaleConfig;
    if (!this.pClusterScaleConfig) {
      return;
    }

    this.cpuSliderOptions = {
      floor: this.pClusterScaleConfig.cpuMin,
      ceil: this.pClusterScaleConfig.cpuMax,
      step: 0.05,
      translate: (value: number) => `${Number(value * 100).toFixed(0)}% CPU`,
    };

    this.ramSliderOptions = {
      floor: this.pClusterScaleConfig.ramMin,
      ceil: this.pClusterScaleConfig.ramMax,
      step: 0.2,
      translate: (value: number) => `${value} GB`,
    };

    this.gpuSliderOptions = {
      floor: this.pClusterScaleConfig.gpuMin,
      ceil: this.pClusterScaleConfig.gpuMax,
      step: 1,
      translate: (value: number) => `${value} GPU`,
    };

    this.storageSliderOptions = {
      floor: this.pClusterScaleConfig.storageMin,
      ceil: this.pClusterScaleConfig.storageMax,
      // step: 1,
      stepsArray: [
        { value: 1 },
        { value: 2 },
        { value: 5 },
        { value: 10 },
        { value: 15 },
        { value: 30 },
        { value: 50 },
        { value: 100 },
        { value: 200 },
        { value: 500 },
        { value: 1000 },
      ],
      translate: (value: number) => `${value} GB`,
    };

    this.replicasSliderOptions = {
      floor: this.pClusterScaleConfig.replicasMin,
      ceil: this.pClusterScaleConfig.replicasMax,
      step: 1,
      translate: (value: number) => `${value}`,
    };

    this.customPlan = {
      capsuleType: this.pClusterScaleConfig.capsuleType,
      clusterId: this.pClusterScaleConfig.clusterId,
      cpu: this.pClusterScaleConfig.cpuMin,
      ram: this.pClusterScaleConfig.ramMin,
      storage: this.pClusterScaleConfig.storageMin,
      gpu: this.pClusterScaleConfig.gpuMin,
      replicas: this.pClusterScaleConfig.replicasMin,
    };
  }

  get clusterScaleConfig(): ClusterScaleConfig {
    return this.pClusterScaleConfig;
  }

  isCustom = false;
  customPlan?: Plan;

  cpuSliderOptions: Options = {
    floor: 0,
    ceil: 4,
    step: 0.1,
    translate: (value: number) => `${Number(value * 100).toFixed(0)}% CPU`,
  };

  ramSliderOptions: Options = {
    floor: 0,
    ceil: 16,
    step: 0.2,
    translate: (value: number) => `${value} GB`,
  };

  gpuSliderOptions: Options = {
    floor: 0,
    ceil: 4,
    step: 1,
    translate: (value: number) => `${value} GPU`,
  };

  storageSliderOptions: Options = {
    floor: 0,
    ceil: 1000,
    stepsArray: [
      { value: 1 },
      { value: 2 },
      { value: 5 },
      { value: 10 },
      { value: 15 },
      { value: 30 },
      { value: 50 },
      { value: 100 },
      { value: 200 },
      { value: 500 },
      { value: 1000 },
    ],
    translate: (value: number) => `${value} GB`,
  };

  replicasSliderOptions: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    translate: (value: number) => `${value}`,
  };

  isPlatformBrowser = true;

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isPlatformBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (!this.plan) {
      this.plan = new Plan();
    } else {
      this.isCustom = !this.plan.id;
    }
  }

  public onPlanSelected(plan: Plan): void {
    this.plan = plan;
    this.isCustom = !plan.id;
    this.planChange.emit(plan);
  }

  public onCpuChange(change: ChangeContext): void {
    this.plan.cpu = change.value;
    this.plan.ram = change.value * 4;
    this.planChange.emit(this.plan);
  }

  public onRamChange(change: ChangeContext): void {
    this.plan.ram = change.value;
    this.plan.cpu = change.value / 4;
    this.planChange.emit(this.plan);
  }

  onPlanValueChange(): void {
    this.planChange.emit(this.plan);
  }
}
