<div class="select-wrapper d-block d-md-none">
  <select
    class="d-block form-control text-center"
    name="tabs"
    [(ngModel)]="selectedOption"
    (change)="navigate()">
    <option
      *ngFor="let tab of tabList"
      [value]="tab.link"
      [routerLink]="tab.link">
      {{ tab.title }}
    </option>
  </select>
</div>
