import { BaseState } from '../base.state';
import { Backup, Build, Capsule } from '../../models';
import { ConfigDto } from '../../models/config-dto';
import { CapsuleCostsResponse } from '../../models/capsule-costs-response';
import { BackupSchedule } from '../../models/backup-schedule';
import { Restore } from '../../models/restore';

export interface CapsuleState extends BaseState {
  capsules: Capsule[];
  usageByCapsule: { [capsuleId: string]: CapsuleCostsResponse };
  configByCapsule: { [capsuleId: string]: ConfigDto };
  buildsByCapsule: { [capsuleId: string]: Build[] };
  backupsByCapsule: { [capsuleId: string]: Backup[] };
  restoresByCapsule: { [capsuleId: string]: Restore[] };
  backupScheduleByCapsule: { [capsuleId: string]: BackupSchedule };
}

export const initialState: CapsuleState = {
  capsules: [],
  usageByCapsule: {},
  configByCapsule: {},
  buildsByCapsule: {},
  backupsByCapsule: {},
  restoresByCapsule: {},
  backupScheduleByCapsule: {},
};
