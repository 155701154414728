export class BillingAddress {
  id?: string;
  address_lines: string;
  administrative_region: string;
  postal_code: string;
  city: string;
  country_code: string;
  created_on?: Date;
  updated_on?: Date;
}
