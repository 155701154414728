<div
  class="icon-holder"
  triggers="hover"
  popoverClass="tooltip-class"
  placement="auto"
  [popover]="popContent">
  <i class="fa fa-question-circle"> </i>
</div>

<ng-template #popContent>
  <ng-content></ng-content>
</ng-template>
