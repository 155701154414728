<div
  class="mx-0 mx-md-4 px-2 my-0 py-2 d-flex pointer justify-content-start align-items-center">
  <img
    *ngIf="icon"
    src="assets/images/v2/{{ icon }}"
    class="dropdown-image-w3"
    width="36"
    height="36"
    alt="Option icon" />
  <img
    *ngIf="src"
    [src]="src"
    class="dropdown-image-w3"
    width="36"
    height="36"
    alt="Option icon" />
  <div *ngIf="!src && !icon && !countryCode" class="placeholder"></div>
  <div
    class="icon fi fis"
    [class]="'fi-' + countryCode | lowercase"
    *ngIf="countryCode"></div>

  <span class="flex-grow-1 mx-3">{{ title }}</span>
  <span
    ><small>{{ subTitle }}</small></span
  >
</div>
