import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Cluster } from '../../models';

@Component({
  selector: 'app-select-cloud',
  templateUrl: './select-cloud.component.html',
  styleUrls: ['./select-cloud.component.scss']
})
export class SelectCloudComponent {

  @Input()
  set clusters(clusters: Array<Cluster>) {
    this.selected = null;
    this.pClusters = clusters;
    this.selectedChange.emit(this.selected);
  }

  pClusters: Array<Cluster>;
  @Input() customPlaceholder:string =  '';

  @Input()
  selected: Cluster;
  @Output()
  selectedChange = new EventEmitter<Cluster>();

  selectCloud(): void {
    this.selectedChange.emit(this.selected);
  }

  constructor() { }
}
