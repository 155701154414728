<div class="capsule-plan">
  <div *ngIf="plans?.length || clusterScaleConfig" class="font-18 bold mt-4">
    Choose a plan
  </div>

  <div *ngIf="!plans?.length && !clusterScaleConfig" class="font-18 bold mt-4">
    No plans available
  </div>

  <div class="d-flex justify-content-center align-items-center h-75"
    *ngIf="loading">
    <app-spinner color="#ffdf00" size="1.5rem" class="mx-1"> </app-spinner>
  </div>

  <div *ngFor="let planOpt of plans"
    class="pointer dark-border rounded px-4 d-flex justify-content-between align-items-center py-2 mb-3"
    [class.active]="plan?.id === planOpt.id" (click)="onPlanSelected(planOpt)" data-testid="capsule-plan-options">
    <h5 class="m-0 mt-1 py-1">
      {{ planOpt.name }}
    </h5>
    <div *ngIf="planOpt.pricePerMonth" class="d-flex mt-1">
      <h5 class="m-0">
        {{ planOpt.pricePerMonth }}
      </h5>
      <div class="m-0">&nbsp;/ month</div>
    </div>
  </div>

  <div *ngIf="clusterScaleConfig"
    class="pointer dark-border rounded px-4 d-flex justify-content-between align-items-center py-2 mb-3"
    [class.active]="isCustom" (click)="onPlanSelected(customPlan)">
    <h5 class="m-0 mt-1 py-1">Custom</h5>
  </div>

  <div class="dark-border rounded p-3"
    *ngIf="isPlatformBrowser && plan && clusterScaleConfig && isCustom">
    <div *ngIf="clusterScaleConfig?.cpu && plan.cpu">
      <div class="bold font-18 mt-4">Select CPU</div>
      <div class="mt-2">
        <ngx-slider [value]="plan.cpu" [options]="cpuSliderOptions"
          (userChange)="onCpuChange($event)">
        </ngx-slider>
      </div>
    </div>

    <div *ngIf="clusterScaleConfig?.ram && plan.ram">
      <h5 class="bold font-18 mt-4">Select RAM</h5>
      <div class="mt-2">
        <ngx-slider [value]="plan.ram" [options]="ramSliderOptions"
          (userChange)="onRamChange($event)">
        </ngx-slider>
      </div>
    </div>

    <div *ngIf="clusterScaleConfig?.gpu && plan.gpu">
      <h5 class="bold font-18 mt-4">Select GPU</h5>
      <div class="mt-2">
        <ngx-slider [(value)]="plan.gpu" [options]="gpuSliderOptions"
          (valueChange)="onPlanValueChange()">
        </ngx-slider>
      </div>
    </div>

    <div *ngIf="clusterScaleConfig?.storage && plan.storage">
      <h5 class="bold font-18 mt-4">Select Storage</h5>
      <div class="mt-2 small mb-4">
        <ngx-slider [(value)]="plan.storage" [options]="storageSliderOptions"
          (valueChange)="onPlanValueChange()">
        </ngx-slider>
      </div>
    </div>

    <div *ngIf="clusterScaleConfig?.replicas && plan.replicas">
      <h5 class="bold font-18 mt-4">Select Scale</h5>
      <div class="mt-2 small mb-4">
        <ngx-slider [(value)]="plan.replicas" [options]="replicasSliderOptions"
          (valueChange)="onPlanValueChange()">
        </ngx-slider>
      </div>
    </div>
  </div>
</div>
