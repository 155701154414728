<ng-select
  class="rounded"
  placeholder="Search/Select a space"
  [items]="spaces"
  bindLabel="name"
  [searchable]="!space"
  [(ngModel)]="space"
  (ngModelChange)="onSpaceChange()">
  >
  <ng-template ng-option-tmp ng-label-tmp let-item="item" let-search="name">
    <div
      class="px-3 py-2 my-0 d-flex pointer justify-content-start align-items-center">
      <div class="me-3">
        <div
          class="space bg-space text-dark circle-name bold d-flex align-items-center justify-content-center">
          <div
            class="circle-icon"
            [class]="
              'fi fis fi-' + item?.cluster?.region?.countryCode | lowercase
            "></div>
        </div>
      </div>

      <div>
        <div class="h5 mb-0">
          {{ item.name }}
        </div>
        <div class="text-secondary small">
          {{ item.slug }}
        </div>
      </div>
    </div>
  </ng-template>
</ng-select>
